<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('MATERIALCADASTRO.SALVANDOMATERIAL')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('MATERIALCADASTRO.APELIDO')">
                                        <b-input maxlength="16" type="text" v-model="model.materialApelido"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('MATERIALCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input maxlength="50" type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third">
                                    <ValidationProvider name="model.materialagrupado.id" v-slot="{ errors, valid }">
                                        <b-field :label="$t('MATERIALCADASTRO.AGRUPAMATERIAL')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('MATERIALCADASTRO.SELECIONEMATERIAL')" class="is-fullwidth" v-model="model.materialAgrupado.id">
                                                <option value="0"></option>
                                                <option v-for="item in materiais" :key="item.id" :value="item.id">{{item.nome}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>                             
                        </article>
                        <div class="tile is-child">
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{ $t('MATERIALCADASTRO.ESTABILIDADE') }}
                                </div>
                                <div class="panel-block">
                                    <div class="columns is-multiline is-fullwidth">
                                        <div class="column is-flex is-flex-direction-column is-4">
                                            <p class="panel-heading is-align-self-stretch has-text-centered">
                                                {{ $t('MATERIALCADASTRO.AMBIENTE') }}
                                            </p>
                                            <div class="is-flex">
                                                <b-field label="De" class="m-0" horizontal>
                                                    <b-input type="number" v-model="model.estabilidadeAmbienteDe" step="0.01" expanded></b-input>
                                                </b-field>
                                                <p class="ml-2 mr-4 has-text-weight-bold custom-middle">ºC</p>
                                                <b-field label="até" class="m-0" horizontal>
                                                    <b-input type="number" v-model="model.estabilidadeAmbienteAte" step="0.01" expanded></b-input>
                                                </b-field>
                                                <p class="ml-2 has-text-weight-bold">ºC</p>
                                            </div>
                                        </div>
                                        <div class="column is-flex is-flex-direction-column is-4">
                                            <p class="panel-heading is-align-self-stretch has-text-centered">
                                                {{ $t('MATERIALCADASTRO.REFRIGERADO') }}
                                            </p>
                                            <div class="is-flex">
                                                <b-field label="De" class="m-0" horizontal>
                                                    <b-input type="number" v-model="model.estabilidadeRefrigeradoDe" step="0.01" expanded></b-input>
                                                </b-field>
                                                <p class="ml-2 mr-4 has-text-weight-bold custom-middle">ºC</p>
                                                <b-field label="até" class="m-0" horizontal>
                                                    <b-input type="number" v-model="model.estabilidadeRefrigeradoAte" step="0.01" expanded></b-input>
                                                </b-field>
                                                <p class="ml-2 has-text-weight-bold">ºC</p>
                                            </div>
                                        </div>
                                        <div class="column is-flex is-flex-direction-column is-4">
                                            <p class="panel-heading is-align-self-stretch has-text-centered">
                                                {{ $t('MATERIALCADASTRO.CONGELADO') }}
                                            </p>
                                            <div class="is-flex">
                                                <b-field label="De" class="m-0" horizontal>
                                                    <b-input type="number" v-model="model.estabilidadeCongeladoDe" step="0.01" expanded></b-input>
                                                </b-field>
                                                <p class="ml-2 mr-4 has-text-weight-bold custom-middle">ºC</p>
                                                <b-field label="até" class="m-0" horizontal>
                                                    <b-input type="number" v-model="model.estabilidadeCongeladoAte" step="0.01" expanded></b-input>
                                                </b-field>
                                                <p class="ml-2 has-text-weight-bold">ºC</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div> 
                    
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('MATERIALCADASTRO.CODINTEG')}}
                                </div>
                                <div class="panel-block">
                                    <table class="table is-bordered is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>{{$t('MATERIALCADASTRO.TIPO')}}</th>
                                                <th>{{$t('MATERIALCADASTRO.CODIGO')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                <td>
                                                    <b-select :placeholder="$t('MATERIALCADASTRO.INTEG')" v-model="item.integradorId">
                                                        <option v-for="option in integradores"
                                                                :value="option.id"
                                                                :key="option.id">
                                                            {{ option.nome }}
                                                        </option>
                                                    </b-select>
                                                </td>
                                                <td>
                                                    <input class="input horario " type="text" v-model="item.codigo">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-success is-fullwidth" @click="addCodigo">
                                        {{$t('MATERIALCADASTRO.ADDCOD')}}
                                    </button>
                                </div>
                            </article>
                        </article>                        
                    </div>                  
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('MATERIALCADASTRO.CANCELAR')}}
                            </b-button>
                            <b-button type="is-success"
                                      v-show="isInRole('material-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('MATERIALCADASTRO.SALVAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style scoped>
    .table {
        table-layout: fixed;
    }
    @media (min-width: 769px) and (max-width: 1280px) {
        .custom-middle {
            margin-right: 0.5rem !important;
        }
        :deep(.field-label.is-normal){
            margin-right: 0.3rem !important;
        }

        .column.is-4{
            -webkit-box-flex: 0;
            -ms-flex: none;
            flex: none;
            width: 50%;
        }
    }
    @media (min-width: 1281px) and (max-width: 1536px) {
        .custom-middle {
            margin-right: 0.3rem !important;
        }
        :deep(.field-label.is-normal){
            margin-right: 0.3rem !important;
        }
    }
</style>
<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,  
                    estabilidadeAmbienteDe: null,
                    estabilidadeAmbienteAte: null,
                    EstabilidadeRefrigeradoDe: null,
                    estabilidadeRefrigeradoAte: null,
                    estabilidadeCongeladoDe: null,
                    estabilidadeCongeladoAte: null,
                    materialApelido: null,
                    codigosIntegrador: [],
                    materialagrupado: null,
                },
                salvandoDados: false,
                materiais: []
            }
        },
        created() {
            this.$http.get('/api/manutencao/material' + (this.$route.params.id ? '?id=' + this.$route.params.id : ''))
                .then(res => {
                    this.model = res.data;
                });

            this.$http.get('/api/search/material')
                .then(res => {
                    this.materiais = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('MATERIALCADASTRO.MANUTENCAO'),
                    this.$t('MATERIALCADASTRO.MATERIAL'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/material`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            }
        }
    }
</script>