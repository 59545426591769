<template>
    <div class="box  is-hero-bar is-main-hero" v-if="lista != null && lista.length > 0">
        <div>
            <div class="panel">
                <div v-for="(guia, index) in lista" :key="index">
                    <div class="panel-block is-block portal-mes"
                         v-if="index == 0 || new Date(guia.dataDeEmissao).getMonth() != new Date(lista[index - 1].dataDeEmissao).getMonth()">
                        <nav class="level">
                            <div class="level-left">
                                <div class="level-item">
                                    <h3 class="label">
                                        {{monthNames[new Date(guia.dataDeEmissao).getMonth()]}} / {{new Date(guia.dataDeEmissao).getFullYear()}}
                                    </h3>
                                </div>
                            </div>
                            <div class="level-right" v-if="(tipo != 'Paciente')">
                                <div class="level-item">
                                    <div class="control">
                                        <b-field>
                                            <b-select placeholder="Ordenar por"
                                                      size="is-small"
                                                      :value="ordenarPor"
                                                      @input="ordenarGuias"
                                                      expanded>
                                                <option value="emissao" checked>{{$t('GUIAS.DATAEMISSAO')}}</option>
                                                <option value="guia">{{$t('GUIAS.NUMEROGUIA')}} </option>
                                                <option value="paciente">{{$t('GUIAS.PACIENTENOME')}}</option>
                                            </b-select>
                                        </b-field>
                                    </div>
                                </div>
                                <div class="level-item">
                                    <b-button size="is-small" @click="filtrar()">{{$t('GUIAS.FILTROS')}}</b-button>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div v-if="guia.guiaId != 0" class="panel-block columns is-multiline is-mobile">
                        <div class="column is-9-desktop" v-if="(tipo != 'Convenio')">
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-6-desktop is-full-mobile">
                                    <small>{{$t('GUIAS.GUIA')}}:</small>
                                    <br />
                                    <b>{{ guia.id }}</b>
                                </div>
                                <div v-if="guia.referencia != null" class="column is-6-desktop is-full-mobile">
                                    <small>{{$t('GUIAS.REFERENCIA')}}:</small>
                                    <br />
                                    <b>{{ guia.referencia }}</b>
                                </div>
                                <div class="column is-2-desktop is-5-mobile">
                                    <small>{{$t('GUIAS.DATAEMISSAO')}}:</small>
                                    <br />
                                    <b>{{ guia.dataDeEmissao | moment("DD/MM/YYYY") }}</b>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                    <small>{{$t('GUIAS.PRAZODEENTREGA')}}:</small>
                                    <br />
                                    <b>{{ guia.dataDeRetorno | moment("DD/MM/YYYY") }}</b>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6"
                                     v-if="(tipo == 'Paciente' && guia.pacienteId != usuarioPacienteId) || (tipo!='Paciente')">
                                    <small>{{$t('SISTEMA.PACIENTE')}}:</small>
                                    <br />
                                    <b>{{ guia.pacienteNome }}</b>
                                </div>
                                <div v-if="config.laboratorioVeterinario && guia.pacienteRaca!=null && guia.pacienteEspecie!=null" class="column is-2">
                                    <small>{{$t('GUIAS.RACAESPECIE')}}:</small>
                                    <br />
                                    <b>{{ guia.pacienteRaca }}/{{ guia.pacienteEspecie }}</b>
                                </div>
                                <div :class="{ 'column is-4' : (config.laboratorioVeterinario) ,'column is-6': (!config.laboratorioVeterinario) }">
                                    <small>{{$t('SISTEMA.SOLICITANTE')}}:</small>
                                    <br />
                                    <b>{{ guia.medicoNome }}</b>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6"
                                     v-if="(guia.responsavelNome)">
                                    <small>{{$t('GUIAS.RESPONSAVEL')}}:</small>
                                    <br />
                                    <b>{{ guia.responsavelNome }}</b>
                                </div>
                            </div>
                            <div class="columns is-mobile is-multiline">
                                <div class="column is-full"
                                     v-for="(exame, index) in guia.exames.filter(x => !x.cancelado && ((!x.exameDeFaturamento && !x.exameDeOutrasDespesas) || x.materialEntregue))"
                                     v-bind:key="index">
                                    <b-tooltip :label="defineTitulo(exame)" multilined>
                                        <b-taglist attached>
                                            <b-tag @click.native="selecionarExame(exame)"
                                                   :class="defineClasses(exame)">
                                                {{ exame.exameNome }}
                                            </b-tag>
                                            <b-tag>
                                                {{ exame.materialNome }}
                                            </b-tag>
                                            <b-tag v-if="exame.assinado">
                                                <span class="flex-container">
                                                    {{$t('GUIAS.RESULTADODISPONIVEL')}}
                                                    <b-icon icon="check" type="is-success" class="ml-2 icon-small"></b-icon>
                                                </span>
                                            </b-tag>
                                            <b-tag v-else>
                                                {{$t('GUIAS.PRAZODEENTREGA')}}: {{ exame.dataDeEntrega | moment("DD/MM/YYYY")}}
                                            </b-tag>

                                            <b-button class="tag" v-if="exame.estudos != null && exame.estudos.length > 0" type="is-primary"
                                                      size="is-small" :href="exame.estudos[0].viewers[0].url" icon-left="eye"
                                                      tag="a" target="_blank" title="Visualizar imagens"></b-button>

                                            <b-button v-if="config.urlDownloadDicom && exame.estudos != null && exame.estudos.length > 0" class="tag"
                                                      size="is-small" @click="downloadDicom(exame.estudos[0].viewers[0].url.split('=').pop().split('/').pop())" icon-left="download"
                                                      title="Download Dicom"></b-button>
                                        </b-taglist>
                                    </b-tooltip>
                                    <div v-show="verMais && exame.dataDeEntrega">
                                        <b-tag type="is-info">
                                            {{ exame.exameNome }}
                                        </b-tag>
                                        <b-tag>
                                            {{$t('GUIAS.DATASOLICITACAO')}}: {{ exame.dataDeSolicitacao | moment("DD/MM/YYYY HH:mm") }}
                                        </b-tag>
                                        <b-tag v-if="exame.materialEntregue" type="is-info">
                                            {{$t('GUIAS.DATAENTREGA')}}:  {{ exame.dataDeEntrega | moment("DD/MM/YYYY HH:mm") }}

                                        </b-tag>
                                        <b-tag v-else type="is-warning">
                                            {{$t('GUIAS.DATAPREVISTA')}}: {{ exame.dataDeEntrega | moment("DD/MM/YYYY HH:mm") }}

                                        </b-tag>
                                        <br>
                                        <b-tag type="is-default" :title="$t('GUIAS.PRECO')" v-if="tipo == 'Convenio' && !usuario.escondePrecos">
                                            {{ exame.preco | currency  }}
                                        </b-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="column is-9-desktop">
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-6-desktop is-full-mobile">
                                    <small>{{$t('GUIAS.GUIA')}}:</small>
                                    <br />
                                    <b>{{ guia.id }}</b>
                                </div>
                                <div v-if="guia.referencia != null" class="column is-6-desktop is-full-mobile">
                                    <small>{{$t('GUIAS.REFERENCIA')}}:</small>
                                    <br />
                                    <b>{{ guia.referencia }}</b>
                                </div>
                                <div class="column is-2-desktop is-5-mobile">
                                    <small>{{$t('GUIAS.DATAEMISSAO')}}:</small>
                                    <br />
                                    <b>{{ guia.dataDeEmissao | moment("DD/MM/YYYY") }}</b>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                    <small>{{$t('GUIAS.RETORNO')}}:</small>
                                    <br />
                                    <b>{{ guia.dataDeRetorno | moment("DD/MM/YYYY") }}</b>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6"
                                     v-if="(tipo == 'Paciente' && guia.pacienteId != usuarioPacienteId) || (tipo!='Paciente')">
                                    <small>{{$t('SISTEMA.PACIENTE')}}:</small>
                                    <br />
                                    <b>{{ guia.pacienteNome }}</b>
                                </div>
                                <div v-if="config.laboratorioVeterinario && guia.pacienteRaca!=null && guia.pacienteEspecie!=null" class="column is-2">
                                    <small>{{$t('GUIAS.RACAESPECIE')}}:</small>
                                    <br />
                                    <b>{{ guia.pacienteRaca }}/{{ guia.pacienteEspecie }}</b>
                                </div>
                                <div :class="{ 'column is-4' : (config.laboratorioVeterinario) ,'column is-6': (!config.laboratorioVeterinario) }">
                                    <small>{{$t('SISTEMA.SOLICITANTE')}}:</small>
                                    <br />
                                    <b>{{ guia.medicoNome }}</b>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6"
                                     v-if="(guia.responsavelNome)">
                                    <small>{{$t('GUIAS.RESPONSAVEL')}}:</small>
                                    <br />
                                    <b>{{ guia.responsavelNome }}</b>
                                </div>
                            </div>
                            <div class="columns is-mobile is-multiline">
                                <div class="column is-2-desktop"
                                     :class="exame.dataDeEntrega ? 'is-full-mobile' : 'is-4-mobile'"
                                     v-for="(exame, index) in guia.exames.filter(x => !x.cancelado && ((!x.exameDeFaturamento && !x.exameDeOutrasDespesas) || x.materialEntregue))"
                                     v-bind:key="index">
                                    <b-tooltip :label="defineTitulo(exame)" multilined>
                                        <b-taglist attached>
                                            <div class="is-flex">
                                                <b-tag @click.native="selecionarExame(exame)"
                                                       :class="defineClasses(exame)">
                                                    {{ exameNome ? exame.exameNome : exame.exameApelido }}
                                                </b-tag>
                                                <b-tag ellipsis>
                                                    {{ exame.materialNome }}
                                                </b-tag>
                                            </div>
                                            <b-button class="tag" v-if="exame.estudos!= null && exame.estudos.length > 0" type="is-primary"
                                                      size="is-small" :href="exame.estudos[0].viewers[0].url" icon-left="eye"
                                                      tag="a" target="_blank" title="Visualizar imagens"></b-button>
                                        </b-taglist>


                                    </b-tooltip>
                                    <div v-show="verMais && exame.dataDeEntrega">
                                        <b-tag type="is-info">
                                            {{ exame.exameNome }}
                                        </b-tag>
                                        <b-tag>
                                            {{$t('GUIAS.DATASOLICITACAO')}}: {{ exame.dataDeSolicitacao | moment("DD/MM/YYYY HH:mm") }}
                                        </b-tag>
                                        <b-tag v-if="exame.materialEntregue" type="is-info">
                                            {{$t('GUIAS.DATAENTREGA')}}:  {{ exame.dataDeEntrega | moment("DD/MM/YYYY HH:mm") }}

                                        </b-tag>
                                        <b-tag v-else type="is-warning">
                                            {{$t('GUIAS.DATAPREVISTA')}}: {{ exame.dataDeEntrega | moment("DD/MM/YYYY HH:mm") }}

                                        </b-tag>
                                        <br>
                                        <b-tag type="is-default" :title="$t('GUIAS.PRECO')" v-if="tipo == 'Convenio' && !usuario.escondePrecos">
                                            {{ exame.preco | currency  }}
                                        </b-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-full-mobile has-text-centered">
                            <div class="columns is-multiline is-mobile">
                                <div v-if="config.naoAbreSemAutorizacao && !guia.convenioTipoParticular && !guia.autorizacaoGuia">
                                    <b-tag type="is-danger" size="is-medium">{{$t('GUIAS.AUTORIZACAO')}}</b-tag>
                                    <p>
                                        <small>
                                            <i>{{$t('GUIAS.CONTATO')}}</i>
                                        </small>
                                    </p>
                                </div>

                                <b-button v-else-if="((guia.exames.filter(x =>
                                    !x.cancelado && ((!x.exameDeFaturamento && !x.exameDeOutrasDespesas)|| x.materialEntregue) &&
                                    ((x.assinado || (x.exameResultadoParcial && x.validado && x.digitado)) && !x.naoPublicaResultadosNaInternet)).length > 0 && !usuario.escondeResultado && !lista.guiaLiberada) || (guia.exames.filter(x => !x.cancelado && ((!x.exameDeFaturamento && !x.exameDeOutrasDespesas) || x.materialEntregue)).filter(exame => exame.assinado || (exame.exameResultadoParcial && exame.digitado)).length == guia.exames.filter(x => !x.cancelado && ((!x.exameDeFaturamento && !x.exameDeOutrasDespesas) || x.materialEntregue)).length && lista.guiaLiberada)) && (guia.exames.filter(x => !x.laudoRestrito).length > 0 || usuario.laudoRestrito)"
                                    icon-left="file-document-outline"
                                    class="column is-12"
                                    @click="visualizarPdf(guia)"
                                    :label="$t('GUIAS.ABRIRRESULTADOS')">
                                </b-button>

                                <p v-if="tipo == 'Convenio'" class="column is-12">

                                    <b-tooltip v-if="(usuario.convenioPodeIncluirGuia && !usuario.escondeIncluirGuia)" :label="$t('GUIAS.EDITAR')" multilined>
                                        <router-link :to="{ name: 'portalconvenioguia', params: { id: guia.id }}" class="is-primary button portal-botoes-convenio-guia">
                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                        </router-link>
                                    </b-tooltip>


                                    <b-tooltip :label="$t('GUIAS.ETIQUETA')" multilined>
                                        <a class="button portal-botoes-convenio-guia is-primary"
                                           @click="imprimirEtiquetaAmostraSelecionada(guia)">
                                            <b-icon icon="barcode" size="is-small"></b-icon>
                                        </a>
                                    </b-tooltip>

                                    <b-tooltip :label="$t('GUIAS.COMPROVANTE')" multilined>
                                        <a class="button portal-botoes-convenio-guia is-primary"
                                           @click="imprimeComprovanteGuia(guia.id,guia.convenioModeloComprovante)">
                                            <b-icon icon="printer" size="is-small"></b-icon>
                                        </a>
                                    </b-tooltip>

                                    <b-tooltip v-if="guia.exames.filter(x =>
                                        !x.cancelado && ((!x.exameDeFaturamento && !x.exameDeOutrasDespesas )|| x.materialEntregue) &&
                                        ((x.assinado || (x.exameResultadoParcial && x.digitado)) && !x.naoPublicaResultadosNaInternet)).length > 0" :label="$t('GUIAS.ENVIAEMAIL')" multilined>
                                        <a class="button portal-botoes-convenio-guia is-primary"
                                           @click="enviarPorEmail(guia)">
                                            <b-icon icon="email" size="is-small"></b-icon>
                                        </a>
                                    </b-tooltip>
                                    <b-tooltip :label="$t('GUIAS.VERMAIS')" multilined>
                                        <b-button class="portal-botoes-convenio-guia"
                                                  icon-left="information"
                                                  :loading="consultandoDados"
                                                  @click="verMaisPorExame()"
                                                  type="is-primary"></b-button>
                                    </b-tooltip>
                                </p>
                            </div>

                            <b-progress size="is-large"
                                        type="is-success"
                                        :value="(guia.exames.filter(x =>
                                !x.cancelado && ((!x.exameDeFaturamento && !x.exameDeOutrasDespesas)|| x.materialEntregue))
                                .filter(exame => exame.assinado || (exame.exameResultadoParcial && exame.digitado)).length /
                                guia.exames.filter(x => !x.exameDePerfil && !x.cancelado && ((!x.exameDeFaturamento && !x.exameDeOutrasDespesas) || x.materialEntregue)).length) * 100"
                                show-value>
                                {{$t('GUIAS.PRONTOS')}}: {{
guia.exames.filter(x => !x.cancelado && ((!x.exameDeFaturamento && !x.exameDeOutrasDespesas) || x.materialEntregue))
                                                            .filter(exame => exame.assinado || (exame.exameResultadoParcial && exame.digitado)).length
                                }} /
                                {{guia.exames.filter(x => !x.exameDePerfil && !x.cancelado && ((!x.exameDeFaturamento && !x.exameDeOutrasDespesas )|| x.materialEntregue)).length}}
                            </b-progress>
                        </div>
                    </div>
                    <div v-else class="panel-block columns is-multiline is-mobile">
                        <div class="column is-10-desktop">
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-12-desktop is-full-mobile">
                                    <h2>{{$t('GUIAS.SEMRESULTADO')}}</h2>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="lista != null">
                    <hr>
                    <b-pagination :total="total"
                                  :current="pag"
                                  :range-before="3"
                                  :range-after="1"
                                  :order="''"
                                  :size="''"
                                  :simple="false"
                                  :rounded="false"
                                  :per-page="'10'"
                                  :icon-prev="'chevron-left'"
                                  :icon-next="'chevron-right'"
                                  @change="onPageChange">
                    </b-pagination>
                </div>
            </div>
        </div>
    </div>

</template>

<style>

    .icon-small {
        width: 1em; /* Ajusta a largura do ícone */
        height: 1em; /* Ajusta a altura do ícone */
    }

    .flex-container {
        display: flex;
        align-items: center; /* Alinha itens verticalmente no centro */
    }

    .ml-2 {
        margin-left: 0.5rem; /* Espaçamento à esquerda do ícone */
    }

    .retorno {
        cursor: pointer;
    }

    .guiaExame {
        cursor: pointer;
    }

    .selecionado {
        border: 2px solid #808080;
    }
</style>

<script>
    import { mapState } from 'vuex';

    export default {
        props: {
            lista: Array,
            tipo: String,
            exameNome: Boolean,
            page: Number,
            pageCount: Number,
            usuarioPacienteId: Number,
            ordenar: String
        },
        data() {
            return {
                total: null,
                pag: null,
                exameSelecionado: [],
                monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
                ],
                guiasComDetalhes: [],
                ordenarPor: 'emissao',
                consultandoDados: false,
                apenasGuiasTotais: false,
                verMais: false,
            };
        },
        computed: {
            ...mapState([
                'config',
                'usuario'
            ])
        },
        created() {
            this.total = this.pageCount * 10;
            this.pag = this.page;
            this.ordenarPor = this.ordenar;

            if (this.lista == null || this.lista.length == 0) {
                this.lista.push({ dataDeEmissao: new Date(), guiaId: 0 })
            }
        },
        methods: {
            onPageChange(page) {
                this.pag = page
                this.$emit('pageChange', page);
            },
            filtrar() {
                this.$emit('filtrar');
            },
            ordenarGuias(value) {
                this.ordenarPor = value;

                this.$emit('ordenar', this.ordenarPor);
            },
            defineClasses(exame) {
                return "guiaExame is-capitalized " +
                    (exame.visualizadoConvenio
                        ? "portal-exame-visualizado-convenio"
                        : exame.bloqueado
                            ? "portal-exame-bloqueado"
                            : (exame.assinado || (exame.exameResultadoParcial && exame.digitado))
                                ? exame.naoPublicaResultadosNaInternet
                                    ? "portal-exame-nao-publica-internet"
                                    : "portal-exame-disponivel"
                                : !exame.materialEntregue
                                    ? exame.recoleta
                                        ? "portal-exame-recoleta"
                                        : "portal-exame-aguardando-material"
                                    : "portal-exame-em-processamento") +
                    (this.exameSelecionado.filter(item => item.guiaId == exame.guiaId && item.item == exame.item).length > 0
                        ? " selecionado"
                        : "")
            },
            defineTitulo(exame) {
                const titulo = exame.exameDePerfil
                    ? "Perfil de Exames"
                    : exame.visualizadoConvenio
                        ? "Resultado visualizado/impresso"
                        : exame.bloqueado
                            ? "Exame bloqueado"
                            : (exame.assinado || (exame.exameResultadoParcial && exame.digitado))
                                ? exame.naoPublicaResultadosNaInternet
                                    ? "Resultado disponível mas não pode ser visualizado pela internet"
                                    : "Resultado disponível"
                                : !exame.materialEntregue
                                    ? exame.recoleta
                                        ? "Nova amostra solicitada"
                                        : "Exame aguardando material"
                                    : "Exame em processamento";

                return exame.exameNome + ": " + titulo
            },
            selecionarExame(exame) {
                if (exame.visualizadoConvenio || exame.assinado || (exame.exameResultadoParcial && exame.digitado)) {
                    if (this.exameSelecionado == null) {
                        this.exameSelecionado = [exame]
                    } else {
                        if (this.exameSelecionado.includes(exame)) {
                            this.exameSelecionado = this.exameSelecionado.filter(function (x) { return x != exame })
                        } else {
                            this.exameSelecionado.push(exame)
                        }
                    }
                }
            },
            visualizarPdf(guia) {
                if (this.usuario.bloqueiaLaudoPrazoVencido && this.tipo == 'Convenio') {
                    this.$http.get('/api/portal/VerificarVencimentoParcelaGuia?guiaId=' + guia.id)
                        .then((res) => {
                            if (!res.data) {
                                this.abrirPdf(guia);
                            }
                            else {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: 'Guia ' + guia.id + ': ' + this.config.mensagemFaturasAtrasadasNoPortal,
                                    type: 'is-warning'
                                })
                            }
                        }, error => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: error.body,
                                type: 'is-danger'
                            })
                        });
                }
                else {
                    this.abrirPdf(guia);
                }
            },
            abrirPdf(guia) {
                const laudoRestrito = guia.exames.some(x => x.laudoRestrito);
                const url = '/api/Portal/Resultado?id=' + guia.id + '&laudoRestrito=' + laudoRestrito + '&usuarioRole=' + this.usuario.laudoRestrito;

                if (this.exameSelecionado != null && this.exameSelecionado.length > 0) {
                    const itensExamesSelecionados = [];

                    //Traz exames normais selecionados
                    this.exameSelecionado.filter(x => !x.laudoRestrito && x.guiaId == guia.id).forEach(exame => {
                        itensExamesSelecionados.push(exame.item);
                    });

                    //Traz exames que são restritos e que usuario possui permissao
                    this.exameSelecionado.filter(x => this.usuario.laudoRestrito && x.laudoRestrito && x.guiaId == guia.id).forEach(exame => {
                        itensExamesSelecionados.push(exame.item);
                    });


                    const itens = '&sel=' + itensExamesSelecionados.join(',');
                    window.open(url + itens);
                }
                else {
                    window.open(url);
                }
            },
            verMaisPorExame() {
                this.verMais = !this.verMais;
            },
            /*
            Função removida do botão "ver mais", por estar fazendo recalculo desnecessário da data de entrega.
            Essa informação já está salva e calculada no guia2.
            */
            // calculoRetornoPorExame(guia) {
            //     if(guia.mostraDetalhes){
            //         guia.exames.forEach(exame => {
            //             exame.dataDeEntrega = null;
            //         });
            //         guia.mostraDetalhes = false;
            //     }else{

            //         this.consultandoDados = true;
            //         this.$http.get('/api/portal/CalculoRetornoPorExame?guiaId=' + guia.id)
            //             .then(res => {
            //                 guia.exames.forEach(exame => {

            //                     if(res.data.filter(item => item.item == exame.item)[0]){
            //                         exame.dataDeEntrega = new Date(res.data.filter(item => item.item == exame.item)[0].dataDeEntrega);
            //                     }


            //                 });

            //                 guia.mostraDetalhes=true;
            //                 this.consultandoDados = false;
            //             });

            //     }

            // },
            enviarPorEmail(guia) {
                this.$buefy.dialog.prompt({
                    title: 'E-mail',
                    message: 'Enviar resultado por email',
                    inputAttrs: {
                        type: 'text',
                        placeholder: 'Email',
                        value: guia.pacienteEmail
                    },
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.$http.post('/api/portal/PdfEmail?id=' + guia.id + '&email=' + res)
                            .then((res) => {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: res.body,
                                    type: 'is-success'
                                })
                            }, error => {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: error.body,
                                    type: 'is-danger'
                                })
                            }
                            )
                    }
                });
            },
            imprimirEtiquetaAmostraSelecionada(guia) {
                this.$notificacaoHub.invoke("EtiquetaAmostraGuia",
                    [guia.id],
                    null, true)
                    .then(() => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Impressão de etiqueta solicitado para guia: ' + guia.id,
                            type: 'is-success',
                            position: 'is-top-right',
                            queue: false
                        })
                    });
            },
            imprimeComprovanteGuia(guia, modelo) {
                window.open('/Guia/Comprovante?id=' + guia + '&modelo=' + modelo);
            },
            downloadDicom(exame) {
                const url = this.config.urlDownloadDicom.replace("{$studyIUID}", exame);
                window.location.href = url;
            }

        }
    }

</script>