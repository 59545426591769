<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('MODALUSUARIOCONVENIO.CONFIGUSUARIO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless is-marginless">
            <div class="panel">
                <div class="panel-block">
                    <small><b>{{usuario.nome}}</b></small>
                </div>
                <div class="panel-block">
                    <div class="buttons is-justified-between">
                        <b-button tag="a"
                                  href="https://www.youtube.com/ProRadis"
                                  target="_blank"
                                  icon-left="youtube">
                            {{$t('MODALUSUARIOCONVENIO.VIDEOS')}}
                        </b-button>
                        <b-button tag="a"
                                  href="https://download.proradis.com.br/LaborLis.Websocket/laborliswebsocketsetup_64bits.msi"
                                  target="_blank"
                                  icon-left="printer">
                            {{$t('MODALUSUARIOCONVENIO.AGENTE')}}
                        </b-button>
                    </div>
                </div>
                <div class="panel-block">
                    <b-button icon-left="key"
                              @click="alterarSenha()"
                              expanded>
                         {{$t('MODALUSUARIOCONVENIO.ALTERARSENHA')}}
                    </b-button>
                </div>
                <div class="panel-block">
                    <b-button icon-left="exit-to-app"
                              @click="logout(); $parent.close();"
                              expanded>
                        {{$t('MODALUSUARIOCONVENIO.SAIRSISTEMA')}}   
                    </b-button>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">{{$t('MODALUSUARIOCONVENIO.FECHAR')}} </button>
        </footer>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        data() {
            return {
            }
        },
        computed: {            
            ...mapState([
                'config',
                'usuario'
            ])
        },
        methods: {   
            ...mapActions([
                'logout'
            ]),
            alterarSenha(){
                this.$router.push({ name: 'portalgerenciar' });
                this.$parent.close();
            }
        }
    }
</script>