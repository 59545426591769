<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Informações sobre amostra</p>
        </header>
        <section class="modal-card-body">
            <div v-for="(amostra,index) in selecionada" :key="index">
                <div class="columns panel-list-item">
                    <div class="column is-1">
                        <p class="heading">{{$t('INFOAMOSTRA.AMOSTRA')}}</p>
                        <p class="subtitle">
                            {{ amostra.id }}
                        </p>
                    </div>
                    <div class="column">
                        <p class="heading">{{$t('INFOAMOSTRA.CODIGOBARRAS')}}</p>
                        <p class="subtitle">
                            {{ amostra.codigoDeBarras }}
                        </p>
                    </div>
                    <div class="column">
                        <p class="heading">{{$t('INFOAMOSTRA.MATERIAL')}}</p>
                        <p class="subtitle">
                            <b>{{ amostra.material.nome }}</b> ({{ amostra.material.id }})
                        </p>
                    </div>
                    <div class="column">
                        <p class="heading">{{$t('INFOAMOSTRA.CONSERVANTE')}}</p>
                        <p class="subtitle">
                            <b>{{ amostra.conservante.nome }}</b> ({{ amostra.conservante.id }})
                        </p>
                    </div>
                    <div class="column" v-if="amostra.acondicionamento">
                        <p class="heading">{{$t('INFOAMOSTRA.ACONDICIONAMENTO')}}</p>
                        <p class="subtitle">
                            <span>{{ amostra.acondicionamento }}</span>
                        </p>
                    </div>
                    <div class="column" v-if="amostra.volume">
                        <p class="heading">{{$t('INFOAMOSTRA.VOLUME')}}</p>
                        <p class="subtitle">
                            <span>{{ amostra.volume }}</span>
                        </p>
                    </div>
                    <div class="column" v-if="amostra.grupoInterface">
                        <p class="heading">{{$t('INFOAMOSTRA.GRUPOINTERFACE')}}</p>
                        <p class="subtitle">
                            <span>{{ amostra.grupoInterface }}</span>
                        </p>
                    </div>
                    <div class="column" v-if="amostra.lote">
                        <p class="heading">{{$t('INFOAMOSTRA.LOTE')}}</p>
                        <p class="subtitle">
                            <span>{{ amostra.lote }}</span>
                        </p>
                    </div>
                    <div class="column" v-if="amostra.codigoIntegracao">
                        <p class="heading">{{$t('INFOAMOSTRA.CODIGO')}}</p>
                        <p class="subtitle">
                            <b>{{ amostra.codigoIntegracao }}</b>
                        </p>
                    </div>
                    <div class="column" v-if="amostra.codigoIntegracaoBarras">
                        <p class="heading">{{$t('INFOAMOSTRA.CODIGOINTEGRACAO')}}</p>
                        <p class="subtitle">
                            <b>{{ amostra.codigoIntegracaoBarras }}</b>
                        </p>
                    </div>
                </div>
                <div class="columns panel-list-item">
                    <div class="column" v-if="amostra.lote">
                        <p class="heading">{{$t('INFOAMOSTRA.CONTADOR')}}</p>
                        <p class="subtitle">
                            <span>{{ amostra.Contador }}</span>
                        </p>
                    </div>
                    <div class="column" v-if="amostra.Origem">
                        <p class="heading">{{$t('INFOAMOSTRA.ORIGEM')}}</p>
                        <p class="subtitle">
                            <span>{{ amostra.Origem }}</span>
                        </p>
                    </div>
                    <div class="column" v-if="amostra.MeioColeta">
                        <p class="heading">{{$t('INFOAMOSTRA.MEIOCOLETA')}}</p>
                        <p class="subtitle">
                            <span>{{ amostra.MeioColeta }}</span>
                        </p>
                    </div>
                    <div class="column" v-if="amostra.amostraMae">
                        <p class="heading">{{$t('INFOAMOSTRA.AMOSTRAMAE')}}</p>
                        <p class="subtitle">
                            <span>{{ amostra.amostraMae }}</span>
                        </p>
                    </div>
                </div>
                <div class="columns panel-list-item" v-for="(log,idxlog) in amostraLog(amostra)" :key="idxlog">
                    <div class="column is-5">
                        <h5 class="is-size-5">{{ log.descricao }}</h5>
                    </div>
                    <div class="column is-3">
                        {{ dataPorExtenso(log.dataHora) }}
                    </div>
                    <div class="column">
                        <p>
                            por <b>{{ log.funcionarioNome ? log.funcionarioNome : log.usuarioNome}}</b>
                        </p>
                        <p v-if="log.unidadeId">
                            na unidade <b>{{ log.unidadeNome }}</b> ({{ log.unidadeId }})
                        </p>
                    </div>
                    <div class="column is-2">
                        <b-tag v-if="log.info">{{ log.info }}</b-tag>
                        <b-tag v-show="log.exclusao" type="is-danger" rounded>X</b-tag>
                    </div>
                </div>
                <a class="columns is-mobile is-centered panel-list-item"
                             v-for="(lote,idxlote) in amostraLotes(amostra)" :key="idxlote"
                             @click="abrirlote(lote.id)">
                    <div class="column is-3 has-text-right">
                        Lote <b>{{ lote.id }}</b>
                    </div>
                    <div class="column is-5">
                        {{ lote.nome }}
                    </div>
                </a>
                <hr />
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$emit('close')">Fechar</button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionada: Array
        },
        data() {
            return {
                lista: [],
                lotes: []
            }
        },
        mounted() {
            this.loadLista()
        },
        methods: {
            dataPorExtenso(dataHora){
                dataHora = new Date(dataHora);
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"][dataHora.getDay()];
                const date = dataHora.getDate();
                const month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][dataHora.getMonth()];
                const year = dataHora.getFullYear();

                return `${day}, ${date} de ${month} de ${year} às ${this.$moment(dataHora).format('HH:mm:ss')}`;
            },
            loadLista() {
                this.$http.get('/api/atendimento/infoAmostra{?id*}', {
                    params: {
                        id: this.selecionada.map(x => x.id)
                    }
                })
                    .then(res => {
                        this.lista = res.data

                        this.selecionada.forEach(x => {
                            this.$http.get('/api/atendimento/infoAmostraLote?id=' + x.id)
                                .then(reslotes => {
                                    this.lotes.push({
                                        amostraId: x.id,
                                        lotes: reslotes.data
                                    })
                                })
                        })
                    })
            },
            amostraLog(amostra) {
                return this.lista.filter(x => x.amostraId === amostra.id)
            },
            amostraLotes(amostra) {
                const amostralotes = this.lotes.filter(x => x.amostraId === amostra.id)
                if (amostralotes != null && amostralotes.length > 0) {                
                    return amostralotes[0].lotes
                }
                else {
                    return []
                }
            },
            abrirlote(id) {
                this.$emit('close')
                this.$router.push({ name: 'amostralote', params: { id }})
            }
        }
    }
</script>