<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('GUIAATENDIMENTO.ALTERARCODIGODEINTEGRACAO')}}</p>
        </header>
        <section class="modal-card-body">
            <b-field>
                <b-input 
                    v-model.number="displayCodigoIntegracao" 
                    type="number"
                    required>
                </b-input>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                      @click="alterarCodigoIntegracaoExame">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            selecionado: Array
        },
        data() {
            return {
                novoCodigoIntegracao: '',
            }
        },
        computed: {
            displayCodigoIntegracao: {
                get() {
                    return this.novoCodigoIntegracao === 0 ? null : this.novoCodigoIntegracao;
                },
                set(value) {
                    this.novoCodigoIntegracao = value === null ? 0 : value;
                }
            }
        },
        mounted() {
            if (this.selecionado.length > 0) {
                this.novoCodigoIntegracao = Number(this.selecionado[0].codigoIntegracao);
            }
        },
        methods: {
            alterarCodigoIntegracaoExame() {
                this.selecionado.forEach(x => {
                    x.codigoIntegracao = this.novoCodigoIntegracao
                })
                this.$emit('close')
            }
        }
    }
</script>