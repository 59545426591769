<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <b-loading :active.sync="isLoading"></b-loading>
            <div class="columns">
                <div class="column custom-is-4">
                    <b-field :label="$t('PROGRESSOAMOSTRA.UNIDADE')">
                        <b-select v-model="unidadeId" @input="loadChartData"
                                  expanded>
                            <option :value="null">Todas</option>
                            <option v-for="(item, index) in unidades"
                                    v-bind:key="index"
                                    :value="item.id">
                                {{item.nome}}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column custom-is-4">
                    <searchIdName :id.sync="localId" :item.sync="local" @item="escolheuLocal" table="Local" :label="$t('PROGRESSOAMOSTRA.LOCALORIGEM')"/>
                </div>
                <div class="column">
                    <b-field :label="$t('PROGRESSOAMOSTRA.ATUALIZAR')">
                        <b-select v-model="intervalo" @input="setTimer">
                            <option value="0">Nunca</option>
                            <option value="1">Cada minuto</option>
                            <option value="2">Cada 2 minutos</option>
                            <option value="5">Cada 5 minutos</option>
                            <option value="10">Cada 10 minutos</option>
                            <option value="20">Cada 20 minutos</option>
                            <option value="30">Cada 30 minutos</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Visualizar">
                        <b-select v-model="dias" @input="loadChartData">
                            <option value="1">últimas 24 horas</option>
                            <option value="2">últimos 2 dias</option>
                            <option value="3">últimos 3 dias</option>
                            <option value="5">últimos 5 dias</option>
                            <option value="7">últimos 7 dias</option>
                            <option value="10">últimos 10 dias</option>
                            <option value="15">últimos 15 dias</option>
                            <option value="20">últimos 20 dias</option>
                            <option value="30">últimos 30 dias</option>
                            <option value="45">últimos 45 dias</option>
                            <option value="60">últimos 60 dias</option>
                            <option value="90">últimos 90 dias</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column custom-is-4">
                    <b-field :label="$t('PROGRESSOAMOSTRA.DEPARTAMENTO')">
                        <b-select v-model="departamento" @input="loadChartData" expanded>
                            <option :value="null">{{$t('PROGRESSOAMOSTRA.TODOS')}}</option>
                            <option v-for="(item, index) in departamentos" :key="index" :value="item.id">
                                {{item.nome}}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column custom-is-4">
                    <searchIdName :id.sync="convenioId" :nome.sync="convenioNome" @item="escolheuConvenio" :label="$t('PROGRESSOAMOSTRA.CONVENIO')" table="Convenio"></searchIdName>
                </div>
                <div class="column">
                    <b-field label="Urgentes">
                        <b-select v-model="urgencia" @input="loadChartData">
                            <option :value="null">Todos</option>
                            <option value="urgentes">Urgentes</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
        </div>
        <b-notification  v-show="!isLoading && chartData.length == 0">{{$t('PROGRESSOAMOSTRA.AMOSTRASNAOECONTRADAS')}}</b-notification>
        <div class="columns is-multiline">
            <div :class="getClass()" v-for="item, index in chartData" :key="index">
                <div class="card" style="min-height: 400px; width: 100%;">
                    <p class="card-header card-header-title"><b>{{title(index)}}</b></p>
                    <template v-for="setor, idx in item">
                        <div v-if="totalGroupChartData(item, index) > 0 && idx == 0" class="card-content is-flex is-justify-content-space-evenly px-0" :key="idx">
                            <div>
                                <div v-for="setor, idx in item" :key="idx">
                                    <div v-if="setor.setorId > 0 || (setor.setorId && !setor.setorId.every(x => x === 0))"
                                         class="is-flex is-justify-content-space-between button custom-margin-x" :key="idx" @click="listarAmostras(setor)">
                                        <div class="is-flex is-justify-content-flex-start">
                                            <b-icon icon="circle"
                                                    size="is-small"
                                                    type="is-grey-dark"
                                                    v-if="setor.setorCor === 'null' || setor.setorCor === '' ">
                                            </b-icon>
                                            <b-icon icon="circle"
                                                    size="is-small"
                                                    :style="{color: `#${setor.setorCor}`}"
                                                    v-else>
                                            </b-icon>
                                            <p style="font-size: 14px;">{{setor.setorNome}}</p>
                                        </div>
                                        <p v-if="index == 'Solicitada'" class="ml-5" style="font-size: 14px;">{{setor.solicitadas}}</p>
                                        <p v-else-if="index == 'TriadaParaTransporte'" class="ml-5" style="font-size: 14px;">{{setor.triadaParaTransporte}}</p>
                                        <p v-else-if="index == 'EmTransito'" class="ml-5" style="font-size: 14px;">{{setor.emTransito}}</p>
                                        <p v-else-if="index == 'Recebida'" class="ml-5" style="font-size: 14px;">{{setor.recebidas}}</p>
                                        <p v-else-if="index == 'RecebidaPeloSetor'" class="ml-5" style="font-size: 14px;">{{setor.recebidaPeloSetor}}</p>
                                        <p v-else-if="index == 'EmAnalise'" class="ml-5" style="font-size: 14px;">{{setor.emAnalise}}</p>
                                        <p v-else-if="index == 'EnviadoParaApoio'" class="ml-5" style="font-size: 14px;">{{setor.enviadoParaApoio}}</p>
                                        <p v-else-if="index == 'SolicitadaNovaAmostra'" class="ml-5" style="font-size: 14px;">{{setor.solicitadaNovaAmostra}}</p>
                                        <p v-else-if="index == 'Incidencia'" class="ml-5" style="font-size: 14px;">{{setor.incidencia}}</p>
                                        <p v-else-if="index == 'Armazenada'" class="ml-5" style="font-size: 14px;">{{setor.armazenada}}</p>
                                    </div>
                                </div>
                            </div>
                            <doughnut-chart-complete 
                                :chart-data="groupChartData(item, index)" 
                                :width="defineTamGrafico()" 
                                :height="defineTamGrafico()" @on-selecionarSetorStatus="listarAmostras" 
                                :chart-id="item"
                                :center-text="totalGroupChartData(item, index).toString() + ' Total'"
                                v-if="totalGroupChartData(item, index) > 0">
                            </doughnut-chart-complete>
                        </div>
                        <div v-else-if="totalGroupChartData(item, index) == 0 && idx == 0" class="card-content is-flex is-justify-content-space-evenly px-0" :key="idx">
                            <div>
                            </div>
                            <doughnut-chart-complete 
                                :chart-data="groupChartData(item, index)" 
                                :width="defineTamGrafico()" 
                                :height="defineTamGrafico()" @on-selecionarSetorStatus="listarAmostras" 
                                :chart-id="item"
                                :center-text="totalGroupChartData(item, index).toString() + ' Total'">
                            </doughnut-chart-complete>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .custom-is-4 {
        -webkit-box-flex: 0;
        flex: none;
        width: 35.33333%;
    }
    .custom-is-3 {
        -webkit-box-flex: 0;
        flex: none;
        width: 24.5%;
    }
    .custom-margin-x{
        margin-left: 0.2rem !important;
        margin-right: 0.2rem !important;
    }
</style>

<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapState } from 'vuex'
    import DoughnutChartComplete from '@/components/Charts/DoughnutChartComplete'
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        data() {
            return {
                dias: 1,
                unidadeId: null,
                chartData: [],
                intervalo: 0,
                timer: null,
                urgencia: null,
                isLoading: true,
                departamento: null,
                departamentos: [], 
                convenioId: null,
                convenioNome: null,
                localId: null,
                local: null,
                chartDataInfo: [],
                screenWidth: window.innerWidth
            }
        },
        components: {
            titlebar,
            searchIdName,
            DoughnutChartComplete
        },
        computed: {
            titleStack() { 
                return [
                    'Análise',
                    this.$t('PROGRESSOAMOSTRA.PROGRESSOAMOSTRA')
                ]
            },
            ...mapState([
                'config',
                'unidades'
            ])
        },
        watch: {
            screenWidth(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.loadChartData()
                }
            }
        },
        created() {            
            this.$http.get('/api/search/departamentos')
                .then(res => {
                    this.departamentos = res.data;
                });
        },
        mounted() {
            this.loadChartData()
            window.addEventListener('resize', () => {
                this.screenWidth = window.innerWidth
            })
        },
        beforeDestroy() {
            if (this.timer != null) {
                clearInterval(this.timer)
            }
            window.removeEventListener('resize', () => {
                this.screenWidth = window.innerWidth
            })
        },
        methods: {
            getClass(){               
                if ( window.innerWidth >= 1600 ){
                    return 'column custom-is-3 my-3 is-flex is-justify-content-center px-0 mr-1';
                }else if ( window.innerWidth >= 1440 && window.innerWidth < 1600 ){
                    return 'column custom-is-3 my-3 is-flex is-justify-content-center px-0 mr-1';
                }else if (window.innerWidth >= 1024 && window.innerWidth < 1440){
                    return 'column custom-is-4 my-3 is-flex is-justify-content-center px-0 mr-1';
                }else if (window.innerWidth <= 1023){
                    return 'column is-3 my-3 is-flex is-justify-content-center px-0';
                }  
            },
            loadChartData() {
                this.isLoading = true
                this.chartData = []
                let url = '/api/analitico/progressoAmostra?dias=' + this.dias
                if (this.unidadeId != null)
                    url += '&unidadeId=' + this.unidadeId
                if (this.urgencia != null)
                    url += '&urgencia=' + this.urgencia
                if(this.departamento != null)
                    url += '&departamento=' + this.departamento
                if(this.convenioId != null)
                    url += '&convenioId=' + this.convenioId
                if(this.localId != null)
                    url += '&localId=' + this.localId
                
                this.$http.get(url)
                    .then(res => {
                        this.chartData = res.data
                        this.isLoading = false
                        this.limitaSetor()
                    });
            },
            limitaSetor(){
                //ajusta os dados de exibição de emAnalise e Recebida, por ser um composição
                for (const chave in this.chartData) {
                    if(chave == "EmAnalise" || chave == "Recebida"){
                        const objetoFilter = {};
                        this.chartData[chave].forEach(item => {
                            if (item.setorId !== 0) {
                                if (!objetoFilter[item.setorId]) {
                                    objetoFilter[item.setorId] = {
                                        ...item,
                                        status: [item.status]
                                    };
                                } else {
                                    objetoFilter[item.setorId].status.push(item.status);
                                }
                            }
                        });
                        this.chartData[chave] = [...Object.values(objetoFilter), ...this.chartData[chave].filter(item => item.setorId === 0)];
                        this.chartData[chave] = this.chartData[chave].sort((a, b) => b.emAnalise - a.emAnalise); //ordena de forma decrescente com base no valor emAnalise
                    }
                }

                if(this.config.maximoSetoresProgressoAmostra && this.config.maximoSetoresProgressoAmostra > 0){
                    let maximoItem = [];
                    let itemRestantes = [];
                    for (const chave in this.chartData) {
                        maximoItem =  this.chartData[chave].slice(0, this.config.maximoSetoresProgressoAmostra);
                        itemRestantes = this.chartData[chave].slice(this.config.maximoSetoresProgressoAmostra);
                        this.chartData[chave] = maximoItem;
                        
                        if(this.totalGroupChartData(this.chartData[chave], chave) > 0 && itemRestantes && itemRestantes.length > 0){
                            switch (chave) {
                                case "Solicitada":
                                    this.chartData[chave].push({
                                        setorId: itemRestantes.filter(item => item.setorId !== 0).map(item => item.setorId),
                                        setorNome: "Outros",
                                        setorCor: "4a4a4a",
                                        solicitadas: itemRestantes.reduce((acc, i) => acc + i.solicitadas, 0),
                                        status: [...new Set(itemRestantes.filter(item => item.status !== 0).map(item => item.status))]
                                    })
                                    break;
                                case "TriadaParaTransporte":
                                    this.chartData[chave].push({
                                        setorId: itemRestantes.filter(item => item.setorId !== 0).map(item => item.setorId),
                                        setorNome: "Outros",
                                        setorCor: "4a4a4a",
                                        triadaParaTransporte: itemRestantes.reduce((acc, i) => acc + i.triadaParaTransporte, 0),
                                        status: [...new Set(itemRestantes.filter(item => item.status !== 0).map(item => item.status))]
                                    })
                                    break;
                                case "EmTransito":
                                    this.chartData[chave].push({
                                        setorId: itemRestantes.filter(item => item.setorId !== 0).map(item => item.setorId),
                                        setorNome: "Outros",
                                        setorCor: "4a4a4a",
                                        emTransito: itemRestantes.reduce((acc, i) => acc + i.emTransito, 0),
                                        status: [...new Set(itemRestantes.filter(item => item.status !== 0).map(item => item.status))]
                                    })
                                    break;
                                case "Recebida":
                                    this.chartData[chave].push({
                                        setorId: itemRestantes.filter(item => item.setorId !== 0).map(item => item.setorId),
                                        setorNome: "Outros",
                                        setorCor: "4a4a4a",
                                        recebidas: itemRestantes.reduce((acc, i) => acc + i.recebidas, 0),
                                        status: [...new Set(itemRestantes.filter(item => item.status !== 0).map(item => item.status))]
                                    })
                                    break;
                                case "RecebidaPeloSetor":
                                    this.chartData[chave].push({
                                        setorId: itemRestantes.filter(item => item.setorId !== 0).map(item => item.setorId),
                                        setorNome: "Outros",
                                        setorCor: "4a4a4a",
                                        recebidaPeloSetor: itemRestantes.reduce((acc, i) => acc + i.recebidaPeloSetor, 0),
                                        status: [...new Set(itemRestantes.filter(item => item.status !== 0).map(item => item.status))]
                                    })
                                    break;
                                case "EmAnalise":
                                    this.chartData[chave].push({
                                        setorId: itemRestantes.filter(item => item.setorId !== 0).map(item => item.setorId),
                                        setorNome: "Outros",
                                        setorCor: "4a4a4a",
                                        emAnalise: itemRestantes.reduce((acc, i) => acc + i.emAnalise, 0),
                                        status: [...new Set(itemRestantes.filter(item => item.status !== 0).map(item => item.status))]
                                    })
                                    break;

                                case "EnviadoParaApoio":
                                    this.chartData[chave].push({
                                        setorId: itemRestantes.filter(item => item.setorId !== 0).map(item => item.setorId),
                                        setorNome: "Outros",
                                        setorCor: "4a4a4a",
                                        enviadoParaApoio: itemRestantes.reduce((acc, i) => acc + i.enviadoParaApoio, 0),
                                        status: [...new Set(itemRestantes.filter(item => item.status !== 0).map(item => item.status))]
                                    })
                                    break;
                                
                                case "SolicitadaNovaAmostra":
                                    this.chartData[chave].push({
                                        setorId: itemRestantes.filter(item => item.setorId !== 0).map(item => item.setorId),
                                        setorNome: "Outros",
                                        setorCor: "4a4a4a",
                                        solicitadaNovaAmostra: itemRestantes.reduce((acc, i) => acc + i.solicitadaNovaAmostra, 0),
                                        status: [...new Set(itemRestantes.filter(item => item.status !== 0).map(item => item.status))]
                                    })
                                    break;
                                case "Incidencia":
                                    this.chartData[chave].push({
                                        setorId: itemRestantes.filter(item => item.setorId !== 0).map(item => item.setorId),
                                        setorNome: "Outros",
                                        setorCor: "4a4a4a",
                                        incidencia: itemRestantes.reduce((acc, i) => acc + i.incidencia, 0),
                                        status: [...new Set(itemRestantes.filter(item => item.status !== 0).map(item => item.status))]
                                    })
                                    break;

                                case "Armazenada":
                                    this.chartData[chave].push({
                                        setorId: itemRestantes.filter(item => item.setorId !== 0).map(item => item.setorId),
                                        setorNome: "Outros",
                                        setorCor: "4a4a4a",
                                        armazenada: itemRestantes.reduce((acc, i) => acc + i.armazenada, 0),
                                        status: [...new Set(itemRestantes.filter(item => item.status !== 0).map(item => item.status))]
                                    })
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
            },
            escolheuConvenio(res) {        
                if(res?.data?.id){
                    this.convenioId = res?.data?.id;
                }
                else if(res?.id){
                    this.convenioId = res.id;
                }
                else{
                    this.convenioId = 0;
                }
                this.loadChartData()
            },  
            escolheuLocal(res){
                if(res?.data?.id){
                    this.localId = res?.data?.id;
                }
                else if(res?.id){
                    this.localId = res.id;
                }
                else{
                    this.localId = 0;
                }
                this.loadChartData()
            },
            title(index) {
                switch (index) {
                    case "Solicitada":
                        return "Solicitada";
                    case "TriadaParaTransporte":
                        return "Triada Para Transporte";
                    case "EmTransito":
                        return "Em Trânsito";
                    case "Recebida":
                        return "Recebida";
                    case "RecebidaPeloSetor":
                        return "Recebida Pelo Setor";
                    case "EmAnalise":
                        return "Em Análise";
                    case "EnviadoParaApoio":
                        return "Enviado Para Apoio";
                    case "SolicitadaNovaAmostra":
                        return "Solicitada Nova Amostra";
                    case "Incidencia":
                        return "Incidência";
                    case "Armazenada":
                        return "Armazenada";
                    default:
                        return "";
                }
            },
            groupChartData(item, idGrafico) {
                let data = []

                if(this.totalGroupChartData(item, idGrafico) == 0){
                    data = [{
                        'setor': '',
                        'cor': "#4a4a4a",
                        'qtde': 0.01 //seta uma quantidade bem baixa para gerar o grafico zerado
                    }]
                    return data
                }

                switch (idGrafico) {
                    case "Solicitada":
                        data = item.map(i => {
                            return{
                                'setor': i.setorNome,
                                'cor': (i.setorCor == 'null' || i.setorCor == '') ? "#4a4a4a" : "#" + i.setorCor,
                                'qtde': i.solicitadas
                            }
                        })
                        break;
                    
                    case "TriadaParaTransporte":
                        data = item.map(i => {
                            return{
                                'setor': i.setorNome,
                                'cor': (i.setorCor == 'null' || i.setorCor == '') ? "#4a4a4a" : "#" + i.setorCor,
                                'qtde': i.triadaParaTransporte
                            }
                        })
                        break;
                    
                    case "EmTransito":
                        data = item.map(i => {
                            return{
                                'setor': i.setorNome,
                                'cor': (i.setorCor == 'null' || i.setorCor == '') ? "#4a4a4a" : "#" + i.setorCor,
                                'qtde': i.emTransito
                            }
                        })
                        break;
                    
                    case "Recebida":
                        data = item.map(i => {
                            return{
                                'setor': i.setorNome,
                                'cor': (i.setorCor == 'null' || i.setorCor == '') ? "#4a4a4a" : "#" + i.setorCor,
                                'qtde': i.recebidas
                            }
                        })
                        break;

                    case "RecebidaPeloSetor":
                        data = item.map(i => {
                            return {
                                'setor': i.setorNome,
                                'cor': (i.setorCor == 'null' || i.setorCor == '') ? "#4a4a4a" : "#" + i.setorCor,
                                'qtde': i.recebidaPeloSetor
                            }
                        })
                        break;
                    
                    case "EmAnalise":
                        data = item.map(i => {
                            return{
                                'setor': i.setorNome,
                                'cor': (i.setorCor == 'null' || i.setorCor == '') ? "#4a4a4a" : "#" + i.setorCor,
                                'qtde': i.emAnalise
                            }
                        })
                        break;

                    case "EnviadoParaApoio":
                        data = item.map(i => {
                            return {
                                'setor': i.setorNome,
                                'cor': (i.setorCor == 'null' || i.setorCor == '') ? "#4a4a4a" : "#" + i.setorCor,
                                'qtde': i.enviadoParaApoio
                            }
                        })
                        break;
                  
                    case "SolicitadaNovaAmostra":
                        data = item.map(i => {
                            return{
                                'setor': i.setorNome,
                                'cor': (i.setorCor == 'null' || i.setorCor == '') ? "#4a4a4a" : "#" + i.setorCor,
                                'qtde': i.solicitadaNovaAmostra
                            }
                        })
                        break;

                    case "Incidencia":
                        data = item.map(i => {
                            return{
                                'setor': i.setorNome,
                                'cor': (i.setorCor == 'null' || i.setorCor == '') ? "#4a4a4a" : "#" + i.setorCor,
                                'qtde': i.incidencia
                            }
                        })
                        break;

                    case "Armazenada":
                        data = item.map(i => {
                            return {
                                'setor': i.setorNome,
                                'cor': (i.setorCor == 'null' || i.setorCor == '') ? "#4a4a4a" : "#" + i.setorCor,
                                'qtde': i.armazenada
                            }
                        })
                        break;
                
                    default:
                        return;
                }

                return data;
            },
            totalGroupChartData(item, idGrafico){
                let total = 0;

                switch (idGrafico) {
                    case "Solicitada":
                        total = item.reduce((acc, i) => acc + i.solicitadas, 0);
                        break;
                    
                    case "TriadaParaTransporte":
                        total = item.reduce((acc, i) => acc + i.triadaParaTransporte, 0);
                        break;
                    
                    case "EmTransito":
                        total = item.reduce((acc, i) => acc + i.emTransito, 0);
                        break;
                    
                    case "Recebida":
                        total = item.reduce((acc, i) => acc + i.recebidas, 0);
                        break;
                    case "RecebidaPeloSetor":
                        total = item.reduce((acc, i) => acc + i.recebidaPeloSetor, 0);
                        break;
                    
                    case "EmAnalise":
                        total = item.reduce((acc, i) => acc + i.emAnalise, 0);
                        break;
                    case "EnviadoParaApoio":
                        total = item.reduce((acc, i) => acc + i.enviadoParaApoio, 0);
                        break;

                    
                    case "SolicitadaNovaAmostra":
                        total = item.reduce((acc, i) => acc + i.solicitadaNovaAmostra, 0);
                        break;

                    case "Incidencia":
                        total = item.reduce((acc, i) => acc + i.incidencia, 0);
                        break;

                    case "Armazenada":
                        total = item.reduce((acc, i) => acc + i.armazenada, 0);
                        break;

                
                    default:
                        return;
                }

                return total
            },
            setTimer() {
                if (this.timer != null) {
                    clearInterval(this.timer)
                }
                if (this.intervalo > 0) {
                    this.timer = setInterval(this.loadChartData, this.intervalo * 60 * 1000)
                }
            },
            defineTamGrafico(){
                if ( window.innerWidth >= 1900 ){
                    return 220;
                }
                else if ( window.innerWidth >= 1600 ){
                    return 200;
                }
                else{
                    return 150;
                }  
            },

            listarAmostras(data) {
                //Remove status duplicados
                const statusArray = Array.isArray(data.status) ? data.status : [data.status];

                const flattenedStatus = statusArray.reduce((acc, val) => {
                    if (Array.isArray(val)) {
                        return acc.concat(val);
                    }
                    return acc.concat([val]);
                }, []);

                const uniqueStatus = [...new Set(flattenedStatus)];

                this.$router.push({
                    name: 'procuradeamostras',
                    query: {
                        setorid: data.setorId,
                        status: uniqueStatus,
                        dias: this.dias,
                        unidadeId: this.unidadeId,
                        convenioId: this.convenioId,
                        localId: this.localId
                    }
                });
            }

        }
    }

</script>