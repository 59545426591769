<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Informações sobre estudo</p>
        </header>
        <section class="modal-card-body">
            <div v-for="(estudo,index) in selecionado" :key="index">
                <div class="columns panel-list-item">
                    <div class="column is-1">
                        <p class="heading">Estudo</p>
                        <p class="subtitle">
                            {{ estudo.id }}
                        </p>
                    </div>
                    <div class="column is-1">
                        <p class="heading">Accession Number</p>
                        <p class="subtitle">
                            {{ estudo.accessionNumber }}
                        </p>
                    </div>
                    <div class="column">
                        <p class="heading">UID</p>
                        <p>
                            {{ estudo.studyUID }}
                        </p>
                    </div>
                    <div class="column is-1">
                        <p class="heading">Modalidades</p>
                        <p class="subtitle">
                            {{ estudo.modsInStudy }}
                        </p>
                    </div>
                    <div class="column">
                        <p class="heading">Descrição</p>
                        <p class="subtitle">
                            {{ estudo.studyDescription }}
                        </p>
                    </div>
                </div>
                <div class="columns panel-list-item" v-for="(log,idxlog) in estudoLog(estudo)" :key="idxlog">
                    <div class="column is-5">
                        <h5 class="is-size-5">{{ log.descricao }}</h5>
                    </div>
                    <div class="column is-3">
                        {{ dataPorExtenso(log.dataHora) }}
                    </div>
                    <div class="column">
                        <p>
                            por <b>{{ log.funcionarioNome ? log.funcionarioNome : log.usuarioNome}}</b>

                        </p>
                        <p v-if="log.unidadeId">
                            na unidade <b>{{ log.unidadeNome }}</b> ({{ log.unidadeId }})
                        </p>
                    </div>
                    <div class="column is-2">
                        <b-tag v-if="log.info">{{ log.info }}</b-tag>
                        <b-tag v-show="log.exclusao" type="is-danger" rounded>X</b-tag>
                    </div>
                </div>
                <hr />
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$emit('close')">Fechar</button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionado: Array
        },
        data() {
            return {
                lista: [],
                lotes: []
            }
        },
        mounted() {
            this.loadLista()
        },
        methods: {
            dataPorExtenso(dataHora){
                dataHora = new Date(dataHora);
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"][dataHora.getDay()];
                const date = dataHora.getDate();
                const month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][dataHora.getMonth()];
                const year = dataHora.getFullYear();

                return `${day}, ${date} de ${month} de ${year} às ${this.$moment(dataHora).format('HH:mm:ss')}`;
            },
            loadLista() {
                this.$http.get('/api/atendimento/infoEstudo{?id*}', {
                    params: {
                        id: this.selecionado.map(x => x.id)
                    }
                })
                .then(res => {
                    this.lista = res.data
                })
            },
            estudoLog(estudo) {
                return this.lista.filter(x => x.estudoId === estudo.id)
            },
        }
    }
</script>