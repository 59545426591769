import { GetterTree } from 'vuex'
import { MutationTree } from 'vuex'
import { NotificacaoMsg } from "./notificacaoMsg"
import { RootState } from '../rootState';

const state: NotificacaoMsg[] = [];

const getters: GetterTree<NotificacaoMsg[], RootState> = {
	Count: (state, getters, rootState) => {
		return state == null
			? 0
			: state.slice(-rootState.config.quantidadeNotificacoesExibidas).length;
	},
}

const mutations: MutationTree<NotificacaoMsg[]> = {
	ADD(state, msg) {
		state.push(msg)
    },
	APAGAR_TODAS(state) {
		state.splice(0)
		localStorage.msgs = []
	},
	APAGAR(state, id) {
		for (let i = 0; i < state.length; i++) {
			if (state[i].id === id) {
				state.splice(i, 1);
				localStorage.msgs = JSON.stringify(state);
				break;
			}
		}
	},    
} 

export default { 
    namespaced: true,
    state,
    getters,
    mutations 
}