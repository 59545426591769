<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('MODALUSUARIO.TITULO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless is-marginless">
            <div class="panel">
                <div class="panel-block">
                    <b-field grouped>
                        <b-field :label="$t('MODALUSUARIO.GUICHE')" expanded>
                            <b-field>
                                <b-input :placeholder="$t('MODALUSUARIO.GUICHEPLACEHOLDER')" expanded v-model="guicheAtual"></b-input>
                            </b-field>
                        </b-field>
                        <b-field :label="$t('MODALUSUARIO.ETIQUETAPADRAO')" expanded>
                            <b-input :placeholder="$t('MODALUSUARIO.ETIQUETAPADRAOPLACEHOLDER')" v-model="etiquetaAtual"></b-input>
                        </b-field>
                    </b-field>
                </div>
                <div class="panel-block">
                    <b-button tag="a"
                              href="/Account/Manage"
                              icon-left="key"
                              expanded>
                        {{$t('MODALUSUARIO.ALTERARSENHA')}}
                    </b-button>
                </div>
                <div class="panel-heading">
                    <small>{{$t('MODALUSUARIO.LICENCIAMENTO')}}</small>
                </div>
                <div class="panel-block">
                    <div style="width: 100%;">
                        <span class="is-flex">{{$t('SOBRE.VERSAO')}} {{config.releaseNumber}}  ({{config.releaseDate}})</span>
                        <span class="is-flex"> {{config.razaoSocial}}</span>
                        <span class="is-flex">CNPJ: {{config.cnpj}}</span>
                        
                        
                    </div>
                </div>
                <div class="panel-heading">
                    <small>{{$t('MODALUSUARIO.LINKSUTEIS')}}</small>
                </div>
                <div class="panel-block">
                    <div class="buttons is-justified-between">
                        <b-button tag="a"
                                  href="https://relacionamento.proradis.com.br/"
                                  target="_blank"
                                  icon-left="headset">
                            {{$t('MODALUSUARIO.SUPORTEREMOTO')}}
                        </b-button>
                        <b-button tag="a"
                                  href="https://www.youtube.com/ProRadis"
                                  target="_blank"
                                  icon-left="youtube">
                            {{$t('MODALUSUARIO.VIDEOSTREINAMENTO')}}
                        </b-button>
                        <b-button tag="a"
                                  href=" https://objectstorage.sa-saopaulo-1.oraclecloud.com/p/5ZMv9IafJyLAp-HQSZqj9ElTinSMygY0tAKPEdEYmWgPqQ4wuOjjCKILrASq1_W0/n/grqpupjbxzib/b/proradis-publish/o/LaborLis.Websocket/laborliswebsocketsetup_64bits.msi"
                                  target="_blank"
                                  icon-left="printer">
                            {{$t('MODALUSUARIO.AGENTEIMPRESSAO')}}
                        </b-button>
                    </div>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot" style="display: block;">
            <button class="button is-success is-pulled-right" type="button" @click="salvarConfiguracoes();$parent.close();">{{$t('MODALUSUARIO.SALVAR')}}</button>
            <button class="button is-pulled-right" type="button" @click="$parent.close()">{{$t('MODALUSUARIO.FECHAR')}}</button>
        </footer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                guicheAtual: localStorage.guicheAtual,
                etiquetaAtual: localStorage.etiquetaAtual
            }
        },
        computed: {
            ...mapState([
                'config'
            ])
        },
        methods: {
            salvarConfiguracoes() {
                localStorage.guicheAtual = this.guicheAtual
                localStorage.etiquetaAtual = this.etiquetaAtual
                this.$emit('close')
            }
        }
    }
</script>