<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('CANCELANDOAMOSTRA.CANCELARAMOSTRA')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('CANCELANDOAMOSTRA.ATENCAO')}}</h5>
                <p>{{$t('CANCELANDOAMOSTRA.CANCELARSELECIONADAS')}}</p>
            </b-notification>
            <b-notification v-if="erroCancelamentoAmostra"
                            type="is-danger">
                {{ erroCancelamentoAmostra }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('CANCELANDOAMOSTRA.AMOSTRAS')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(amostra,index) in selecionada" :key="index">{{ amostra.codigoDeBarras }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('CANCELANDOAMOSTRA.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosCancelamentoAmostra"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosCancelamentoAmostra = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoCancelamentoAmostraSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosCancelamentoAmostra" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="confirmarCancelamentoAmostra">{{$t('CANCELANDOAMOSTRA.CONFIRMARCANCELAMENTO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('CANCELANDOAMOSTRA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionada: Array
        },
        data() {
            return {
                procurarMotivosCancelamentoAmostra: '',
                listaMotivosCancelamentoAmostra: [],
                motivoCancelamentoAmostraSelecionado: [],
                erroCancelamentoAmostra: null,
            }
        },
        computed: {
            filteredMotivosCancelamentoAmostra() {
                return this.procurarMotivosCancelamentoAmostra
                    ? this.listaMotivosCancelamentoAmostra
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosCancelamentoAmostra.toUpperCase()) > -1)
                    : this.listaMotivosCancelamentoAmostra
            },
        },
        watch: {
            motivoCancelamentoAmostraSelecionado(newValue) {
                if (newValue.length > 1) {
                    this.motivoCancelamentoAmostraSelecionado = [newValue[newValue.length - 1]];
                }
            },
        },
        mounted() {
            this.loadListaMotivosCancelamentoAmostra()
        },
        methods: {
            loadListaMotivosCancelamentoAmostra() {
                this.$http.get('/api/search/MotivosCancelamentoAmostra')
                    .then(m => {
                        this.listaMotivosCancelamentoAmostra = m.data
                    })
            },
            confirmarCancelamentoAmostra() {
                if(this.motivoCancelamentoAmostraSelecionado == null ||this.motivoCancelamentoAmostraSelecionado.length == 0) {

                    return this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('TOAST.MOTIVOCANCELAMENTO'),
                        type: 'is-warning'
                    })
                }

                this.$http.post('/api/atendimento/CancelarAmostra', {
                    guiaId: this.guia.id,
                    id: this.selecionada.map(x => x.id),
                    motivoIds: this.motivoCancelamentoAmostraSelecionado
                })
                    .then((res) => {
                        if (res.body.erro) {
                            this.erroCancelamentoAmostra = res.body.erro
                        } else {
                            this.$emit('loadGuia')
                            this.$emit('close')
                        }
                    })
            },

        }
    }
</script>