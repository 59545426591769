<template>
    <section>
        <div class="box" >
            <article class="level">
                <div class="level-left">
                    <div class="is-light">
                        <form @submit.prevent="incluirAmostra" method="get">
                            <label><strong>{{$t('LOTES.CODIGOBARRAS')}}</strong></label>
                            <div class="field has-addons">
                                <div class="control">
                                    <b-input v-model="amostraCodigoDeBarras" ref="amostraCodigoDeBarras" id="amostraCodigoDeBarras"></b-input>
                                </div>
                                <div class="control">
                                    <b-button native-type="submit" :loading="loading" type="is-success">
                                        {{$t('LOTES.INCLUIR')}}
                                    </b-button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="column">
                    <label><strong>{{$t('INCLUIRLOTEAMOSTRAS.ACONDICIONAMENTO')}}</strong></label>
                    <b-select expanded :disabled="amostraBipada" v-model="acondicionamento">
                        <option :value="0"></option>
                        <option :value="1">{{ $t('INCLUIRLOTEAMOSTRAS.AMBIENTE') }}</option>
                        <option :value="2">{{ $t('INCLUIRLOTEAMOSTRAS.REFRIGERADO') }}</option>
                        <option :value="3">{{ $t('INCLUIRLOTEAMOSTRAS.CONGELADO') }}</option>
                    </b-select>
                </div>
                </div>
                <div class="level-right">
                    <b-button native-type="button" class="button is-success" @click="incluirPendencias()" :loading="loading">{{$t('LOTES.INCLUIRPENDENCIA')}}</b-button>
                </div>
            </article>
        </div>

        <b-table v-if="amostras.length > 0" :data="amostras"
                 :loading="loading"
                 striped
                 hoverable
                :checked-rows.sync="checkedRows"
                checkable
                :checkbox-position="checkboxPosition">

            <template>
                <b-table-column v-slot="props" field="Contador" :label="$t('LOTES.CONTADOR')">
                    <span class="tag is-pulled-right is-dark">{{ (props.index + 1) }}</span>
                </b-table-column>

                <b-table-column v-slot="props" field="Amostra" :label="$t('LOTES.AMOSTRA')">
                    {{ props.row.amostraCodigoDeBarras }}
                </b-table-column>

                <b-table-column v-slot="props" field="Paciente" :label="$t('SISTEMA.PACIENTE')">
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column v-slot="props" field="Material" :label="$t('LOTES.MATERIAL')">
                    {{ props.row.materialNome }}
                </b-table-column>

                <b-table-column v-slot="props" field="Conservante" :label="$t('LOTES.CONSERVANTE')">
                    {{ props.row.conservanteNome }}
                </b-table-column>

                <b-table-column v-slot="props" field="Exames" :label="$t('LOTES.EXAMES')">
                    <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                        {{item}}<span> </span>
                    </span>
                </b-table-column>
            </template>
        </b-table>
        
        <div class='box' style="margin-top: 10px;">
            <div class="columns">
                <div class="column is-2">
                    <label><strong>{{$t('LOTES.TEMPERATURA')}} (C°)</strong></label>
                    <input type="number" v-model="temperatura" class="input" />
                </div>
                <div class="column is-2">
                    <label><strong>{{$t('LOTES.LATITUDE')}}</strong></label>
                    <input type="text" v-model="latitude" class="input" readonly/>
                </div>
                <div class="column is-2">
                    <label><strong>{{$t('LOTES.LONGITUDE')}}</strong></label>
                    <input type="text" v-model="longitude" class="input" readonly/>
                </div>
            </div>
        </div>
        <nav class="notification is-light level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success" icon-left="check-circle" @click="enviarLote();" :loading="loading">
                        {{$t('SISTEMA.ENVIAR')}}
                    </b-button>

                </p>
            </div>
        </nav>
    </section>    
</template>


<script>
    import { mapState } from 'vuex';
    import modalAmostraTempInadequada from '../../../components/atendimento/modalAmostraTempInadequada.vue';

    export default {
        data() {
            return {
                amostras: [],
                amostraCodigoDeBarras: "",
                temperatura: "",
                latitude: "",
                longitude: "",
                erros: [],
                checkedRows: [],
                loading: false,
                checkboxPosition: 'left',
                acondicionamento: 0,
                temTemplatePdf: false,
                temTemplateTxt: false
            }
        },
        computed: {
            amostraBipada() {
                return this.amostras.some(a => a.amostraId) && this.acondicionamento != 0;
            },
            ...mapState([
                'config',
            ]),
        },
        created() {
            this.$getLocation({})
                .then(coordinates => {
                    this.latitude = coordinates.lat
                    this.longitude = coordinates.lng
                });
        },
        mounted() {
            this.validaTemplates();
        },
        methods: {
            validaTemplates() {
                console.log("Tela foi gerada!");
                this.$http.get(`/api/portal/ValidaTemplates`)
                          .then(res => {
                              this.temTemplatePdf = res.data.temTemplatePdf;
                              this.temTemplateTxt = res.data.temTemplateTxt;
                             console.log(res);
                             console.log(res.data);
                                                                            
                         });
            },
            incluirAmostra(){
                
                if(this.amostraCodigoDeBarras !== "" && this.amostraCodigoDeBarras !== null){
                    this.loading = true;
                    if(this.config.acondicionamentoLoteObrigatorio && this.acondicionamento == 0){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('INCLUIRLOTEAMOSTRAS.ACONDICIONAMENTOBRIGATORIO'),
                            type: 'is-warning',
                            queue: false
                        });
                        this.loading = false;
                        return;
                    }
                    if(this.amostras.length){
                        for(const amostra of this.amostras){
                            if(amostra.amostraCodigoDeBarras == this.amostraCodigoDeBarras){
                                this.$buefy.dialog.alert({
                                    title: this.$t('LOTES.AMOSTRASELECIONADA'),
                                    message:  this.$t('LOTES.AMOSTRAJAINCLUIDA', { msg:  this.amostraCodigoDeBarras }), 
                                    type:'is-warning',
                                    hasIcon: true
                                });
                                this.loading = false;
                                return;
                            }
                        }
                    }

                    this.$http.get(`/api/portal/EnviarLoteIncluir?codigoDeBarras=${this.amostraCodigoDeBarras}&acondicionamento=${this.acondicionamento}`)
                    .then(res => res.data)
                    .then(data => {
                        if (data != null && data.length != 0) {
                            if(data.msg != null){
                                this.$buefy.dialog.alert({
                                    message: data.msg,
                                    type: 'is-warning',
                                    hasIcon: true,
                                    ariaModal: true,
                                    confirmText: this.$t('SISTEMA.OK'),                    
                                    onConfirm: () => {
                                        this.$refs.amostraCodigoDeBarras.focus();
                                    }
                                });
                            }
                            else { 
                                for (const i in data) {
                                    this.amostras.push(data[i]);
                                    this.checkedRows.push(data[i]);
                                }
                            }
                        }
                        else {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: 'Amostra não encontrada. Verifique o conteúdo pesquisado e tente novamente.',
                                type: 'is-warning',
                                queue: false
                            });
                        }
    
                        this.$refs.amostraCodigoDeBarras.focus();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: error,
                            type: 'is-danger',
                            queue: false
                        });
                        this.loading = false;
                    }); 
    
                    this.amostraCodigoDeBarras = "";
                }
            },
            incluirPendencias(){
                this.loading = true;
                
                this.$http.get('/api/portal/EnviarLoteIncluirPendencias')
                .then(res => res.data)
                .then(data => {
                    if (data != null && data.length != 0) {
                        for (const i in data) {
                            this.amostras.push(data[i]);
                            this.checkedRows.push(data[i]);
                        }
                    }
                    else {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Amostra não encontrada. Verifique o conteúdo pesquisado e tente novamente.',
                            type: 'is-warning',
                            queue: false
                        });
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error,
                        type: 'is-danger',
                        queue: false
                    });
                    this.loading = false;
                }); 

                this.amostraCodigoDeBarras = "";
            },
            enviarLote(){
                const enviarLote = {
                    amostras: this.checkedRows, 
                    temperatura: this.temperatura, 
                    latitude: this.latitude, 
                    longitude: this.longitude
                };

                const amostrasParaValidar = enviarLote.amostras.filter(a => this.isInvalidAmostra(a));

                if(enviarLote.temperatura !== "" && amostrasParaValidar && amostrasParaValidar.length){
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalAmostraTempInadequada,
                        props: {
                            amostras: amostrasParaValidar,
                            temperaturaInformada: parseFloat(enviarLote.temperatura.replace(",", ".")),
                            envioInternoOuPortal: true
                        },
                        events: {
                            enviarLoteApi: () => this.enviarLoteApi(enviarLote)
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })
                }
                else{
                    this.enviarLoteApi(enviarLote);
                }
            },
            enviarLoteApi(enviarLote){
                this.loading = true;

                this.$http.post(`/api/portal/EnviarLote`, enviarLote )
                    .then(({ data }) => {
                        this.loading = false;
                        if(data != null){
                            const loteId = data;
                            if (this.temTemplatePdf && this.temTemplateTxt) {

                                this.imprimirLotePdf(loteId);
                                this.imprimirLoteTxt(loteId);

                            } else if (this.temTemplatePdf) {

                                this.imprimirLotePdf(loteId);

                            } else if (this.temTemplateTxt) {

                                this.imprimirLoteTxt(loteId);
                            }
                            
                        }
                    })
                    .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error,
                        type: 'is-danger',
                        queue: false
                    });
                        this.loading = false;
                    }); 

                this.amostras = [];
                this.checkedRows = [];
            },
            imprimirLoteTxt(loteId){
                window.open('/Guia/comprovanteLoteTxt?id='+loteId + '&filtroLote=' + true);
            },
            imprimirLotePdf(loteId) {
                window.open('/Guia/comprovanteLotePdf?id='+loteId + '&filtroLote=' + true);
            },
            isInvalidAmostra(amostra) {
                const temperaturaInformada = parseFloat(this.temperatura.replace(",", "."));

                if(amostra.amostraAcondicionamento &&
                   (amostra.estabilidadeAmbienteDe != null || amostra.estabilidadeAmbienteAte != null ||
                   amostra.estabilidadeCongeladoDe != null || amostra.estabilidadeCongeladoAte != null ||
                   amostra.estabilidadeRefrigeradoDe != null || amostra.estabilidadeRefrigeradoAte != null)){
                    
                    if(amostra.amostraAcondicionamento == 1){
                        // maior que
                        if(amostra.estabilidadeAmbienteDe != null && amostra.estabilidadeAmbienteAte == null){
                            return !(temperaturaInformada >= amostra.estabilidadeAmbienteDe)
                        }
                        // menor que
                        else if(amostra.estabilidadeAmbienteDe == null && amostra.estabilidadeAmbienteAte != null){
                            return !(temperaturaInformada <= amostra.estabilidadeAmbienteAte)
                        }
                        // entre
                        else if(amostra.estabilidadeAmbienteDe != null && amostra.estabilidadeAmbienteAte != null){
                            return !(temperaturaInformada >= amostra.estabilidadeAmbienteDe && temperaturaInformada <= amostra.estabilidadeAmbienteAte)
                        }
                    }
                    else if(amostra.amostraAcondicionamento == 2){
                        // maior que
                        if(amostra.estabilidadeRefrigeradoDe != null && amostra.estabilidadeRefrigeradoAte == null){
                            return !(temperaturaInformada >= amostra.estabilidadeRefrigeradoDe)
                        }
                        // menor que
                        else if(amostra.estabilidadeRefrigeradoDe == null && amostra.estabilidadeRefrigeradoAte != null){
                            return !(temperaturaInformada <= amostra.estabilidadeRefrigeradoAte)
                        }
                        // entre
                        else if(amostra.estabilidadeRefrigeradoDe != null && amostra.estabilidadeRefrigeradoAte != null){
                            return !(temperaturaInformada >= amostra.estabilidadeRefrigeradoDe && temperaturaInformada <= amostra.estabilidadeRefrigeradoAte)
                        }
                    }
                    else if(amostra.amostraAcondicionamento == 3){
                        // maior que
                        if(amostra.estabilidadeCongeladoDe != null && amostra.estabilidadeCongeladoAte == null){
                            return !(temperaturaInformada >= amostra.estabilidadeCongeladoDe)
                        }
                        // menor que
                        else if(amostra.estabilidadeCongeladoDe == null && amostra.estabilidadeCongeladoAte != null){
                            return !(temperaturaInformada <= amostra.estabilidadeCongeladoAte)
                        }
                        // entre
                        else if(amostra.estabilidadeCongeladoDe != null && amostra.estabilidadeCongeladoAte != null){
                            return !(temperaturaInformada >= amostra.estabilidadeCongeladoDe && temperaturaInformada <= amostra.estabilidadeCongeladoAte)
                        }
                    }
                }
            },
        }
    }
</script>