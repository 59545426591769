<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <div class="columns is-multiline">
                <div class="column is-3">
                    <b-field label="Reemitir Mapa de Trabalho">
                        <b-input type="text" v-model="numeroBancada"></b-input>
                    </b-field>
                </div>
                <div class="column is-3" v-if="guiaId">
                    <b-field label="Guia">
                        <b-tag type="is-info">{{guiaId}}</b-tag>
                    </b-field>
                </div>
                <div class="column is-3">
                    <br>
                    <span class="tag is-warning is-large" v-if="numeroBancadaAlterado">Reimpressão</span>
                </div>
                <div class="column is-3">
                    <label><strong>{{$t('CHECKPOINT.DESCRICAO')}}</strong></label>
                    <input type="text" v-model="descricao" class="input" maxlength="100"/>
                </div>
            </div>
            <div class="columns is-multiline">
                <div class="column is-6">
                    <searchIdName :id.sync="unidade.id" :nome.sync="unidade.nome" :item.sync="unidade" table="Unidade" :label="$t('CHECKPOINT.UNIDADE')"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="local.id" :nome.sync="local.nome" :item.sync="local" table="Local" :label="$t('CHECKPOINT.LOCALORIGEM')"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="setor.id" :nome.sync="setor.nome" :item.sync="setor" table="Setor" :label="$t('CHECKPOINT.SETOR')"/>
                </div>
                <div class="column is-6">
                    <searchIdName :id.sync="convenio.id" :nome.sync="convenio.nome" :item.sync="convenio" table="Convenio" :label="$t('CHECKPOINT.CONVENIO')"/>
                </div>
            </div>
            <div class="columns is-multiline">
                <div class="column is-6">                    
                    <label class="label">{{$t('CHECKPOINT.EXAME')}}</label>
                    <b-autocomplete field="nome"
                            ref="autocomplete"
                            v-model="exame"
                            :placeholder="$t('CHECKPOINT.PROCURAREXAME')"
                            :data="listaExameAutocomplete"
                            :loading="isFetching"
                            @typing="procurarExameAutocomplete"
                            @select="selectItem">
                        <template slot-scope="props">
                            <div class="media">
                                <div class="media-left">
                                    <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                </div>
                                <div class="media-content">
                                    {{ props.option.nome }}
                                </div>
                            </div>
                        </template>
                    </b-autocomplete>
                    <div class="columns" v-if="exames.length > 0">
                        <div class="column">
                            <b-table :data="exames" striped hoverable>
                                <template>                                            

                                    <b-table-column  v-slot="props" field="exameId" label="Código">
                                        {{ props.row.exameId }}
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="exameApelido" label="Apelido">
                                        {{ props.row.exameApelido }}
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="" label="Remover?">
                                        <b-button
                                            icon-left="close"
                                            type="is-danger"
                                            @click.prevent.stop="removerExame(props.row)"
                                        ></b-button>
                                    </b-table-column>

                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
                <div class="column is-6">
                    <label class="label">{{$t('CHECKPOINT.ENVIARINTEGRADOR')}}</label>
                    <b-select placeholder="Integrador" v-model="integradorId">
                        <option value=""></option>
                        <option v-for="option in integradores"
                                :value="option.id"
                                :key="option.id">
                            {{ option.nome }}
                        </option>
                    </b-select>                    
                </div>
            </div>
            <hr> 
            <div class="columns is-multiline">
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-if="numeroBancadaAlterado" disabled v-model="gravaStatus">{{$t('CHECKPOINT.STATUSEXAMEENVIADO')}}</b-checkbox>
                        <b-checkbox v-else v-model="gravaStatus">{{$t('CHECKPOINT.STATUSEXAMEENVIADO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="imprimeRascunho">{{$t('CHECKPOINT.IMPRIMERASCUNHO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="somenteUrgentes">{{$t('CHECKPOINT.EXAMESURGENTES')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="somenteAmostrasRecebidasNoSetor">{{$t('CHECKPOINT.SOMENTEAMOSTRASRECEBIDASNOSETOR')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="somenteSemDigitacao">{{$t('CHECKPOINT.SOMENTESEMDIGITAÇÃO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeConvenio">{{$t('CHECKPOINT.EXIBECONVENIO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeMedico">{{$t('CHECKPOINT.EXIBEMEDICO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeMaterial">{{$t('CHECKPOINT.EXIBEMATERIAL')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeConservante">{{$t('CHECKPOINT.EXIBECONSERVANTE')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeLocal">{{$t('CHECKPOINT.EXIBELOCAL')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeDataRetorno">{{$t('CHECKPOINT.EXIBEDATARETORNO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeDataNascimento">{{$t('CHECKPOINT.EXIBEDATANASCIMENTO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeInscricao">{{$t('CHECKPOINT.EXIBEINSCRICAO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeLeito">{{$t('CHECKPOINT.EXIBELEITO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeUltimosResultados">{{$t('CHECKPOINT.EXIBEULTIMOSRESULTADOS')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="emitepapelRazao">{{$t('CHECKPOINT.EXIBEPAPELRAZAO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="incluirEspacoAssinatura">{{$t('CHECKPOINT.INCLUIRESPACOASSINATURA')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeCodigoBarras">{{$t('CHECKPOINT.EXIBECODIGOBARRAS')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeObservacaoPaciente">{{$t('CHECKPOINT.EXIBEOBSPACIENTE')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeDataColeta">{{$t('CHECKPOINT.EXIBEDATACOLETA')}}</b-checkbox>
                    </b-field>
                </div>
                 <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="exibeDataRetornoPorExame">{{$t('CHECKPOINT.EXIBEDATARETORNOEXAME')}}</b-checkbox>
                    </b-field>
                </div>
            </div>  
            <hr> 
            <div class="columns is-multiline">
                <div class="column tile is-12">

                    <div class="column is-3">
                        <label class="label">Separar por</label>
                        <b-field>
                            <b-checkbox v-model="separaSetores">{{$t('CHECKPOINT.SEPARARSETORES')}}</b-checkbox>
                        </b-field>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Separar por</label>
                        <b-field>
                            <b-checkbox v-model="separaLocais">{{$t('CHECKPOINT.SEPARARLOCAIS')}}</b-checkbox>
                        </b-field>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Separar por</label>
                        <b-field>
                            <b-checkbox v-model="separaExames">{{$t('CHECKPOINT.SEPARAREXAMES')}}</b-checkbox>
                        </b-field>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Separar por</label>
                        <b-field>
                            <b-checkbox v-model="separaPacientes">Pacientes por página</b-checkbox>
                        </b-field>
                    </div>
                </div>
            </div>  
            <div class="columns is-multiline">
                <div class="column tile is-12">
                    <div class="column is-3">
                        <label class="label">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="ordemLote">
                            {{$t('CHECKPOINT.ORDEMLOTE')}}
                        </b-radio>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="codigo">
                            {{$t('CHECKPOINT.CODIGOGUIA')}}
                        </b-radio>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="nomePaciente">
                            {{$t('CHECKPOINT.NOMEPACIENTE')}}
                        </b-radio>
                    </div>
                    <div class="column is-3">
                        <label class="label" style="visibility: hidden;">Ordenar por</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="dataSolicitacao">
                            {{$t('CHECKPOINT.DATASOLICITACAO')}}
                        </b-radio>
                    </div>
                </div>
            </div>            
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="arrow-left-bold-circle"
                                @click="$router.back();">
                                {{$t('CHECKPOINT.VOLTAR')}}
                    </b-button>
                    
                    <b-button type="is-success"
                                native-type="button"
                                :loading="loading"
                                @click="enviar()"
                                icon-left="check-circle">
                        Enviar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>


<script>
    import { mapState, mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import debounce from 'lodash/debounce'

    export default {
        data() {
            return {
                local: {
                    id: null,
                    nome: null
                },
                unidade: {
                    id: null, 
                    nome: null
                },
                setor: {
                    id: null,  
                    nome: null
                },
                convenio: {
                    id: null, 
                    nome: null
                },
                exames: this.$route.query?.exames ?? [],
                exame: null,
                numeroBancada: null,
                numeroBancadaInicial: null,
                ordena: localStorage.bancadaOrdenaPelaOrdemLote != null && localStorage.bancadaOrdenaPelaOrdemLote ? 'ordemLote' :
                    localStorage.bancadaOrdenaPeloNomeDoPaciente != null && localStorage.bancadaOrdenaPeloNomeDoPaciente ? 'nomePaciente' :
                    localStorage.bancadaOrdenaPelaDataDeSolicitacao != null && localStorage.bancadaOrdenaPelaDataDeSolicitacao ? 'dataSolicitacao' : localStorage.bancadaOrdenaPeloCodigoDaGuia != null && localStorage.bancadaOrdenaPeloCodigoDaGuia ? 'codigo' : 'ordemLote',
                gravaStatus: localStorage.bancadaGravaStatusComoEnviado ?? true, 
                imprimeRascunho: false, 
                somenteUrgentes: localStorage.bancadaSomenteExamesUrgentes ?? false, 
                somenteAmostrasRecebidasNoSetor: false,
                somenteSemDigitacao: localStorage.bancadaSomenteExamesSemResultado ?? false, 
                exibeConvenio: localStorage.bancadaExibeConvenio ?? false, 
                exibeMedico: localStorage.bancadaExibeNomeDoMedico ?? false, 
                exibeMaterial: false, 
                exibeConservante: localStorage.bancadaExibeConservante ?? false, 
                exibeLocal: false, 
                exibeDataColeta: false,
                exibeDataRetornoPorExame: localStorage.bancadaExibeDataRetornoPorExame ?? false,
                exibeDataRetorno: false, 
                exibeDataNascimento: false, 
                exibeInscricao: localStorage.bancadaExibeInscricao ?? false, 
                exibeLeito: localStorage.bancadaExibeLeito ?? false, 
                exibeUltimosResultados: false, 
                emitepapelRazao: localStorage.bancadaEmiteEmPapelRazao ?? false, 
                incluirEspacoAssinatura: false, 
                exibeCodigoBarras: false, 
                exibeObservacaoPaciente: localStorage.bancadaExibeObservacaoPaciente ?? false, 
                loading: false,
                integradorId: null,
                isFetching: false,
                listaExameAutocomplete: [],
                selected: null,
                numeroBancadaAlterado: false,
                separaSetores: localStorage.bancadaSeparaSetoresPorPagina ?? true,
                separaLocais: localStorage.bancadaSeparaLocaisPorPagina ?? false,
                separaExames: localStorage.bancadaSeparaExamesPorPagina ?? false,
                separaPacientes: localStorage.bancadaSeparaPacientesPorPagina ?? false,
                guiaId: this.$route.query?.guiaId ?? null
            };
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'unidadeId',
                'integradores',
                'config'
            ]),
            titleStack() {
                return [
                    'Análise',
                    this.$t('CHECKPOINT.EMISSAOBANCADA'),
                ]
            },                 
        },
        components: {
            titlebar,
            searchIdName
        }, 
        mounted() {     
            this.unidade.id = this.unidadeId;
            this.imprimeRascunho = this.config.tagsMapaTrabalho.ImprimeRascunhoComCamposResultados ? this.config.tagsMapaTrabalho.ImprimeRascunhoComCamposResultados : localStorage.bancadaExibeRascunho ?? false;
            this.exibeMaterial = this.config.tagsMapaTrabalho.ExibeMaterial != null ? String(this.config.tagsMapaTrabalho.ExibeMaterial) : String(localStorage.bancadaExibeMaterial ?? false)
            this.exibeLocal = this.config.tagsMapaTrabalho.ExibeLocalOrigem ? this.config.tagsMapaTrabalho.ExibeLocalOrigem : localStorage.bancadaExibeLocal ?? false;
            this.exibeDataColeta = this.config.tagsMapaTrabalho.ExibeDataHoraColeta ? this.config.tagsMapaTrabalho.ExibeDataHoraColeta : localStorage.bancadaExibeDataDeColeta ?? false;
            this.exibeDataRetorno = this.config.tagsMapaTrabalho.ExibeDataPrazodeEntrega ? this.config.tagsMapaTrabalho.ExibeDataPrazodeEntrega : localStorage.bancadaExibeDataDeRetorno ?? false;
            this.exibeDataNascimento = this.config.tagsMapaTrabalho.ExibeDataNascimento ? this.config.tagsMapaTrabalho.ExibeDataNascimento : localStorage.bancadaExibeNascimento ?? false;
            this.exibeUltimosResultados = this.config.tagsMapaTrabalho.ExibeUltimosResultados ? this.config.tagsMapaTrabalho.ExibeUltimosResultados : localStorage.bancadaExibeUltimosResultados ?? false;
            this.incluirEspacoAssinatura = this.config.tagsMapaTrabalho.IncluEspacoAssinaturaResponsaveis ? this.config.tagsMapaTrabalho.IncluEspacoAssinaturaResponsaveis : localStorage.bancadaIncluiEspacoParaAssinaturas ?? false;
            this.exibeCodigoBarras = this.config.tagsMapaTrabalho.ExibeCodigoBarras ? this.config.tagsMapaTrabalho.ExibeCodigoBarras : localStorage.bancadaExibeCodigoDeBarrasDaAmostra ?? false;
            this.somenteAmostrasRecebidasNoSetor = this.config.tagsMapaTrabalho.SomenteAmostrasRecebidasNoSetor ? this.config.tagsMapaTrabalho.SomenteAmostrasRecebidasNoSetor : localStorage.bancadaSomenteAmostrasRecebidasNoSetor ?? false;
            this.carregarOpcoesBancada();
            
        },
        watch: {
            separaSetores: function(val){
                if(val){
                    this.separaLocais = false;
                    this.separaExames = false;
                }
            },
            separaExames: function(val){
                if(val){
                    this.separaSetores = false;
                    this.separaLocais = false;
                    this.separaPacientes = false;
                }
                else {
                    if(!this.separaPacientes){
                        this.separaSetores = true;
                    }
                }
            },
            separaPacientes: function(val){
                if(val){
                    this.separaExames = false;
                    this.separaLocais = false;
                    this.ordena = 'nomePaciente'
                }
            },
        },
        methods:{
            enviar(){
                this.salvarOpcoesSelecionadas();

                const bancadaModel = {
                    'SeparaSetoresPorPagina' : this.separaSetores,
                    'SeparaLocaisPorPagina': this.separaLocais,
                    'SeparaExamesPorPagina': this.separaExames,
                    'SeparaPacientesPorPagina': this.separaPacientes,

                    'OrdenaPeloCodigoDaGuia': this.ordena == 'codigo',
                    'OrdenaPeloNomeDoPaciente': this.ordena == 'nomePaciente',
                    'OrdenaPelaDataDeSolicitacao': this.ordena == 'dataSolicitacao',
                    'OrdenaPelaOrdemLote': this.ordena == 'ordemLote',

                    'GravaStatusComoEnviado': this.gravaStatus,
                    'SomenteExamesUrgentes': this.somenteUrgentes,
                    'SomenteAmostrasRecebidasNoSetor': this.somenteAmostrasRecebidasNoSetor,
                    'ExibeRascunho': this.imprimeRascunho,
                    'SomenteExamesSemResultado': this.somenteSemDigitacao,
                    'ExibeConvenio': this.exibeConvenio,
                    'ExibeNomeDoMedico': this.exibeMedico,
                    'ExibeMaterial': this.exibeMaterial,
                    'ExibeConservante': this.exibeConservante,
                    'ExibeLocal': this.exibeLocal,
                    'ExibeDataDeColeta': this.exibeDataColeta,
                    'ExibeDataRetornoPorExame': this.exibeDataRetornoPorExame,
                    'ExibeDataDeRetorno': this.exibeDataRetorno,
                    'ExibeNascimento': this.exibeDataNascimento,
                    'ExibeInscricao': this.exibeInscricao,
                    'ExibeLeito': this.exibeLeito,
                    'ExibeUltimosResultados': this.exibeUltimosResultados,
                    'EmiteEmPapelRazao': this.emitepapelRazao,
                    'IncluiEspacoParaAssinaturas': this.incluirEspacoAssinatura,
                    'ExibeCodigoDeBarrasDaAmostra': this.exibeCodigoBarras,
                    'ExibeObservacaoPaciente': this.exibeObservacaoPaciente,
                    
                    'UnidadeId': this.unidade.id,
                    'UnidadeNome': this.unidade.nome,
                    'LocalDeOrigemId': this.local.id,
                    'LocalDeOrigemNome': this.local.nome,
                    'SetorId': this.setor.id,
                    'SetorNome': this.setor.nome,
                    'ConvenioId': this.convenio.id,
                    'ConvenioNome': this.convenio.nome,
                    'Numero': this.numeroBancada ? this.numeroBancada : 0,
                    'NumeroNovaBancada': 0,
                    'exameId': this.exames.map(x => x.exameId),

                    'guiaId': this.guiaId,

                    'Descricao': this.descricao,
                };

                
                this.loading = true;
                this.$http.post('/api/analitico/BancadaRel', bancadaModel, { responseType: 'arraybuffer' })
                    .then(res => res.data)
                    .then(data => {
                        const file = new Blob([data], { type: 'application/pdf' });
                        const fileURL = window.URL.createObjectURL(file);
                        window.open(fileURL);
                    })
                    .catch((error) => {
                        if (error.status == 400)
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: `A bancada ${this.numeroBancada} não existe.`,
                                type: 'is-danger'
                            })

                        if (error.status == 404)
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: 'Não existem registros para os filtros selecionados.',
                                type: 'is-danger'
                            })
                    })
                    .finally(() => {
                        this.loading = false;
                    });

            },
            salvarOpcoesSelecionadas() {
                const pre = "bancada";

                localStorage.setItem(pre + "SeparaSetoresPorPagina", this.separaSetores);
                localStorage.setItem(pre + "SeparaLocaisPorPagina", this.separaLocais);
                localStorage.setItem(pre + "SeparaExamesPorPagina", this.separaExames);
                localStorage.setItem(pre + "SeparaPacientesPorPagina", this.separaPacientes);

                localStorage.setItem(pre + "OrdenaPeloCodigoDaGuia", this.ordena == 'codigo');
                localStorage.setItem(pre + "OrdenaPeloNomeDoPaciente", this.ordena == 'nomePaciente');
                localStorage.setItem(pre + "OrdenaPelaDataDeSolicitacao", this.ordena == 'dataSolicitacao');
                localStorage.setItem(pre + "OrdenaPelaOrdemLote", this.ordena == 'ordemLote');

                

                localStorage.setItem(pre + "SomenteExamesUrgentes", this.somenteUrgentes);
                localStorage.setItem(pre + "SomenteAmostrasRecebidasNoSetor", this.somenteAmostrasRecebidasNoSetor);
                localStorage.setItem(pre + "ExibeRascunho", this.imprimeRascunho);
                localStorage.setItem(pre + "SomenteExamesSemResultado", this.somenteSemDigitacao);
                localStorage.setItem(pre + "ExibeConvenio", this.exibeConvenio);
                localStorage.setItem(pre + "ExibeNomeDoMedico", this.exibeMedico);
                localStorage.setItem(pre + "ExibeMaterial", this.exibeMaterial);
                localStorage.setItem(pre + "ExibeConservante", this.exibeConservante);
                localStorage.setItem(pre + "ExibeLocal", this.exibeLocal);
                localStorage.setItem(pre + "ExibeDataDeColeta", this.exibeDataColeta);
                localStorage.setItem(pre + "ExibeDataRetornoPorExame", this.exibeDataRetornoPorExame);
                localStorage.setItem(pre + "ExibeDataDeRetorno", this.exibeDataRetorno);
                localStorage.setItem(pre + "ExibeNascimento", this.exibeDataNascimento);
                localStorage.setItem(pre + "ExibeInscricao", this.exibeInscricao);
                localStorage.setItem(pre + "ExibeLeito", this.exibeLeito);
                localStorage.setItem(pre + "ExibeUltimosResultados", this.exibeUltimosResultados);
                localStorage.setItem(pre + "EmiteEmPapelRazao", this.emitepapelRazao);
                localStorage.setItem(pre + "IncluiEspacoParaAssinaturas", this.incluirEspacoAssinatura);
                localStorage.setItem(pre + "ExibeCodigoDeBarrasDaAmostra", this.exibeCodigoBarras);
                localStorage.setItem(pre + "ExibeObservacaoPaciente", this.exibeObservacaoPaciente);
            },
            carregarOpcoesBancada() {
                this.opcoesBancada = '';

                this.carregarOpcao('SeparaSetoresPorPagina');
                this.carregarOpcao('SeparaLocaisPorPagina');
                this.carregarOpcao('SeparaExamesPorPagina');
                this.carregarOpcao('SeparaPacientesPorPagina');
                this.carregarOpcao('SomenteExamesUrgentes');
                this.carregarOpcao('SomenteAmostrasRecebidasNoSetor');
                this.carregarOpcao('SomenteExamesSemResultado');
                this.carregarOpcao('ExibeConvenio');
                this.carregarOpcao('ExibeNomeDoMedico');
                this.carregarOpcao('ExibeMaterial');
                this.carregarOpcao('ExibeConservante');
                this.carregarOpcao('ExibeLocal');
                this.carregarOpcao('ExibeDataDeRetorno');
                this.carregarOpcao('ExibeDataDeColeta');
                this.carregarOpcao('ExibeDataRetornoPorExame');
                this.carregarOpcao('ExibeLeito');
                this.carregarOpcao('ExibeRascunho');
                this.carregarOpcao('ExibeUltimosResultados');
                this.carregarOpcao('ExibeNascimento');
                this.carregarOpcao('ExibeInscricao');
                this.carregarOpcao('OrdenaPeloCodigoDaGuia');
                this.carregarOpcao('OrdenaPeloNomeDoPaciente');
                this.carregarOpcao('OrdenaPelaDataDeSolicitacao');
                this.carregarOpcao('OrdenaPelaOrdemLote');
                this.carregarOpcao('EmiteEmPapelRazao');
                this.carregarOpcao('IncluiEspacoParaAssinaturas');
                this.carregarOpcao('ExibeCodigoDeBarrasDaAmostra');
                this.carregarOpcao('ExibeObservacaoPaciente');
            },
            carregarOpcao(nome) {
                const pre = "bancada";

                if (localStorage.getItem(pre + nome) && localStorage.getItem(pre + nome) == 'true') {
                    this.opcoesBancada += this.opcoesBancada + nome + ",";
                }
            },
            procurarExameAutocomplete: debounce(function (name) {
                if (!name.length) {
                    this.listaExameAutocomplete = []
                    return
                }
                this.isFetching = true

                const params = [
                    `procurar=${name}`,
                    `page=1`                                      
                ].join('&')

                this.$http.get(`/api/manutencao/exames?${params}`)
                    .then(({ data }) => {
                        this.listaExameAutocomplete = data.lista
                    })
                    .catch((error) => {
                        this.listaExameAutocomplete = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            selectItem(option) {
                if(option != null){
                    this.exames.push({
                        exameId: option.id,
                        exameNome: option.nome,
                        exameApelido: option.apelido
                    });
                }
                
                setTimeout(() => this.exame = '', 10);
                
                this.$refs.autocomplete.focus()
            },
            removerExame(exame){
                this.exames = this.exames?.filter((i) => i.exameId != exame.exameId);
            }
        }
    }
</script>