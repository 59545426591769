<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{ $t('MODALAMOSTRATEMPINADEQUADA.AMOSTRATEMPINADEQUADATITLE') }}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">
                <b-table :data="amostras" striped hoverable :checked-rows.sync="checkedRows" class="fullwidth panel-block" :checkable="!envioInternoOuPortal">
                    <template>
                        <b-table-column v-slot="props" field="codigoDeBarras" :label="$t('MODALAMOSTRATEMPINADEQUADA.CODIGODEBARRAS')">
                            {{ props.row.amostraCodigoDeBarras  }}
                        </b-table-column>

                        <b-table-column v-slot="props"  field="paciente" :label="$t('MODALAMOSTRATEMPINADEQUADA.NOMEDOPACIENTE')">
                            {{ props.row.pacienteNome }}
                        </b-table-column>

                        <b-table-column v-slot="props" field="temperaturaAdequada" :label="$t('MODALAMOSTRATEMPINADEQUADA.TEMPERATURAADEQUADA')">
                            <!-- maior que -->
                            <span v-if="props.row.estabilidadeAmbienteDe != null && props.row.estabilidadeAmbienteAte == null && props.row.amostraAcondicionamento == 1">
                                <p>{{ `&ge; ${props.row.estabilidadeAmbienteDe}º` }}</p>
                            </span>
                            <span v-if="props.row.estabilidadeRefrigeradoDe != null && props.row.estabilidadeRefrigeradoAte == null && props.row.amostraAcondicionamento == 2">
                                <p>{{ `&ge; ${props.row.estabilidadeRefrigeradoDe}º` }}</p>
                            </span>
                            <span v-if="props.row.estabilidadeCongeladoDe != null && props.row.estabilidadeCongeladoAte == null && props.row.amostraAcondicionamento == 3">
                                <p>{{ `&ge; ${props.row.estabilidadeCongeladoDe}º` }}</p>
                            </span>
                            <!--------------->
                            <!-- menor que -->
                            <span v-if="props.row.estabilidadeAmbienteDe == null && props.row.estabilidadeAmbienteAte != null && props.row.amostraAcondicionamento == 1">
                                <p>{{ `&le; ${props.row.estabilidadeAmbienteAte}º` }}</p>
                            </span>
                            <span v-if="props.row.estabilidadeRefrigeradoDe == null && props.row.estabilidadeRefrigeradoAte != null && props.row.amostraAcondicionamento == 2">
                                <p>{{ `&le; ${props.row.estabilidadeRefrigeradoAte}º` }}</p>
                            </span>
                            <span v-if="props.row.estabilidadeCongeladoDe == null && props.row.estabilidadeCongeladoAte != null && props.row.amostraAcondicionamento == 3">
                                <p>{{ `&le; ${props.row.estabilidadeCongeladoAte}º` }}</p>
                            </span>
                            <!--------------->
                            <!---- entre ---->
                            <span v-if="props.row.estabilidadeAmbienteDe != null && props.row.estabilidadeAmbienteAte != null && props.row.amostraAcondicionamento == 1">
                                <p>{{ `Entre ${props.row.estabilidadeAmbienteDe}º e ${props.row.estabilidadeAmbienteAte}º` }}</p>
                            </span>
                            <span v-if="props.row.estabilidadeRefrigeradoDe != null && props.row.estabilidadeRefrigeradoAte != null && props.row.amostraAcondicionamento == 2">
                                <p>{{ `Entre ${props.row.estabilidadeRefrigeradoDe}º e ${props.row.estabilidadeRefrigeradoAte}º` }}</p>
                            </span>
                            <span v-if="props.row.estabilidadeCongeladoDe != null && props.row.estabilidadeCongeladoAte != null && props.row.amostraAcondicionamento == 3">
                                <p>{{ `Entre ${props.row.estabilidadeCongeladoDe}º e ${props.row.estabilidadeCongeladoAte}º` }}</p>
                            </span>
                            <!--------------->
                        </b-table-column>

                        <b-table-column v-slot="props" field="exameApelido">
                            <b-tooltip expanded :label="props.row.examesApelidos.join(',')"
                                    type="is-dark"
                                    position="is-top" >
                                    <b-tag type="is-info">{{ props.row.examesApelidos[0] }}</b-tag>
                            </b-tooltip>
                        </b-table-column>
                    </template>
                </b-table>

                <div class="panel-block" v-if="!envioInternoOuPortal">
                    <b-field :label="$t('MODALAMOSTRATEMPINADEQUADA.MOTIVO')">
                        <b-select :placeholder="$t('MODALAMOSTRATEMPINADEQUADA.MOTIVOPLACEHOLDER')"
                                  v-model="motivoRecebimentoSelecionado">
                            <option v-for="(motivo,index) in listaMotivosRecebimento" :key="index"
                                    :value="motivo.id">
                                {{ motivo.descricaoInterno }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot is-flex is-justify-content-space-between">
            <div v-if="!envioInternoOuPortal">
                <b-button type="is-warning"
                          @click="aceitarRestricao()">{{ $t('MODALAMOSTRATEMPINADEQUADA.ACEITARCOMRESTRICAO') }}</b-button>
                <b-button type="is-danger"
                          @click="rejeitar()">{{ $t('MODALAMOSTRATEMPINADEQUADA.REJEITARSOLICITARNOVAAMOSTRA') }}</b-button>
            </div>
            <div v-else>
                <b-button type="is-success"
                          @click="continuarFluxo()">{{ $t('Continuar') }}</b-button>
            </div>
            <b-button @click="$emit('close')">{{ $t('SISTEMA.CANCELAR') }}</b-button>
        </footer>
    </div>
</template>

<style scoped>
    :deep(.b-table .table-wrapper){
        width: 100%;
    }
</style>

<script>
    export default {
        props: {
            amostras: Array,
            temperaturaInformada: Number,
            envioInternoOuPortal: Boolean,
        },
        data() {
            return {
                checkedRows: [],
                listaMotivosRecebimento: [],
                motivoRecebimentoSelecionado: [],
            }
        },
        created() {
            this.$http.get('/api/search/MotivosRecebimento')
                .then(m => {
                    this.listaMotivosRecebimento = m.data
                })
        },
        methods: {
            aceitarRestricao(){
                if(this.checkedRows.length == 0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message:  this.$t('MODALAMOSTRATEMPINADEQUADA.AMOSTRASCHECKEDWARNING'),
                        type: 'is-warning'
                    })
                    
                    return;
                }

                if(this.motivoRecebimentoSelecionado == null || this.motivoRecebimentoSelecionado.length == 0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message:  this.$t('TOAST.MOTIVOCANCELAMENTO'),
                        type: 'is-warning'
                    })
                    
                    return;
                }

                this.checkedRows.forEach(cr => {
                    const index = this.amostras.findIndex(a => a.amostraId === cr.amostraId);
                    if (index >= 0) {
                        this.amostras[index].motivoIncidencia = this.motivoRecebimentoSelecionado;
                        this.amostras[index].incidencia = 'R';
                    }
                });

                this.$emit('atualizarAmostras', this.amostras)
                this.$emit('close');
            },
            rejeitar(){
                if(this.checkedRows.length == 0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message:  this.$t('MODALAMOSTRATEMPINADEQUADA.AMOSTRASCHECKEDWARNING'),
                        type: 'is-warning'
                    })
                    
                    return;
                }

                if(this.motivoRecebimentoSelecionado == null || this.motivoRecebimentoSelecionado.length == 0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('TOAST.MOTIVOCANCELAMENTO'),
                        type: 'is-warning'
                    })
                    
                    return;
                }

                this.checkedRows.forEach(cr => {
                    const index = this.amostras.findIndex(a => a.amostraId === cr.amostraId);
                    if (index >= 0) {
                        this.amostras[index].motivoIncidencia = this.motivoRecebimentoSelecionado;
                        this.amostras[index].incidencia = 'N';
                    }
                });

                this.$emit('atualizarAmostras', this.amostras)
                this.$emit('close');
            },
            continuarFluxo(){
                this.$emit('enviarLoteApi')
                this.$emit('close');
            }
        }
    }
</script>