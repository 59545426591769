<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOLOCALCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="100" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-block">
                                    <b-switch v-model="model.acidenteOcupacional">
                                        {{$t('TIPOLOCALCADASTRO.ACIDENTE')}}
                                    </b-switch>
                                </div>
                                <div class="panel-block">
                                    <b-switch v-model="model.coletaDomiciliar">
                                       {{$t('TIPOLOCALCADASTRO.COLETADOM')}} 
                                    </b-switch>
                                </div>
                                <div class="panel-block">
                                    <b-switch v-model="model.postoDeColeta">
                                       {{$t('TIPOLOCALCADASTRO.POSTODECOLETA')}} 
                                    </b-switch>
                                </div>
                                <div class="panel-block">
                                    <b-switch v-model="model.nucleoTecnico">
                                       {{$t('TIPOLOCALCADASTRO.NUCLEOTECNICO')}} 
                                    </b-switch>
                                </div>
                            </article>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('TIPOLOCALCADASTRO.CANCELAR')}} 
                            </b-button>
                            <b-button v-show="isInRole('tipoDeLocal-alterar')"
                                      type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('TIPOLOCALCADASTRO.SALVAR')}} 
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    coletaDomiciliar: false,
                    acidenteOcupacional: false,
                    postoDeColeta: false,
                    nucleoTecnico: false,
                },
                salvandoDados: false
            }
        },
        created() {
            this.$http.get('/api/manutencao/tipodelocal?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('TIPOLOCALCADASTRO.MANUTENCAO'),
                    this.$t('TIPOLOCALCADASTRO.TIPOLOCAL'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;

                if(this.model.id == null){
                    this.model.id = 0
                }
                
                this.$http.post(`/api/manutencao/tipodelocal`, this.model)
                    .then(() => {
                        this.salvandoDados = false
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false
                        throw error
                    });
            }
        }
    }
</script>