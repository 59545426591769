<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('MODALFILAATENDIMENTO.SENHAATENDIMENTO')}}</p>
            <b-tag type="is-info" rounded>{{ lista.length }}</b-tag>
        </header>
        <section class="modal-card-body">
            <div class="columns">
                <div class="column">
                    <b-field :label="$t('MODALFILAATENDIMENTO.UNIDADE')">
                        <b-input disabled v-model="unidadeNome"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field :label="$t('MODALFILAATENDIMENTO.GUICHE')">
                        <b-input v-model="guicheAtual" @input="salvarGuicheAtual"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field :label="$t('MODALFILAATENDIMENTO.SITUACAO')">
                        <b-select v-model="guicheTipo" @input="salvarGuicheTipo"
                                    expanded>
                            <option value="Todos">
                                {{$t('MODALFILAATENDIMENTO.TODOS')}}
                            </option>
                            <option value="Recepcao">
                                {{$t('MODALFILAATENDIMENTO.RECEPCAO')}}
                            </option>
                            <option value="Coleta">
                                {{$t('MODALFILAATENDIMENTO.COLETA')}}
                            </option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <th>{{$t('MODALFILAATENDIMENTO.TIPO')}}</th>
                        <th>{{$t('MODALFILAATENDIMENTO.SEQUENCIA')}}</th>
                        <th>{{$t('MODALFILAATENDIMENTO.SITUACAO')}}</th>
                        <th>{{$t('MODALFILAATENDIMENTO.CHAMAR')}}</th>
                        <th>{{$t('MODALFILAATENDIMENTO.ATENDER')}}</th>
                        <th>{{$t('MODALFILAATENDIMENTO.CANCELAR')}}</th>
                        <th>{{$t('MODALFILAATENDIMENTO.TEMPOESPERA')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item, index in lista.filter(x => (guicheTipo == 'Todos' || x.situacao == guicheTipo))" :key="index">
                        <td><b-tag size="is-medium" :type="buttonType(item)">{{ item.tipo }}</b-tag></td>
                        <td class="has-text-centered"><b-tag size="is-medium" :type="seqType(item)">{{ item.sequencia }}</b-tag></td>
                        <td class="has-text-centered"><b-tag size="is-medium" :type="seqType(item)">{{ item.situacao }}</b-tag></td>
                        <td class="has-text-centered"><b-button type="is-warning" icon-left="bell-ring" @click="chamar(item)"></b-button></td>
                        <td class="has-text-centered"><b-button type="is-success" icon-left="play-circle" @click="atender(item);$parent.close();"></b-button></td>
                        <td class="has-text-centered"><b-button type="is-danger" icon-left="close-circle" @click="cancelar(item)"></b-button></td>
                        <td class="has-text-centered">{{ formatDate(item.dataAbertura) }}</td>                    </tr>
                </tbody>
            </table>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">{{$t('MODALFILAATENDIMENTO.FECHAR')}}</button>
        </footer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import moment from 'moment';
    import 'moment/locale/pt-br';

    export default {
        data() {
            return {
                tipos: [],
                guicheAtual: localStorage.guicheAtual,
                guicheTipo: localStorage.guicheTipo,
                chamandoSenhaId: null,
                isLoading: true
            }
        },
        computed: {
            ...mapState([
                'unidadeId',
                'unidades',
                'usuario'
            ]),
            ...mapState('senhaAtendimento', [
                'lista'
            ]),
            unidadeNome() {
                return this.unidades.find(x => x.id == this.unidadeId).nome
            }
        },
        mounted() {
            this.$notificacaoHub.invoke("ListarSenhaAtendimento",
                this.unidadeId)
                .then(() => {
                    this.isLoading = false
                })
                .catch(e => {
                    this.isLoading = false
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: e.toString(),
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                })
        },
        methods: {
            formatDate(date) {
                moment.locale('pt-br');
                return moment(date).fromNow();
            },
            seqType(senha) {
                if (senha.id == this.chamandoSenhaId) {
                    return "is-link blink"
                } else if(senha.situacao == 'Coleta') {
                    return "is-info"
                }
                else {
                    return "is-dark"
                }
            },
            buttonType(senha) {
                if (senha.tipoId == 1)
                    return 'is-primary'
                else if (senha.tipoId == 3)
                    return 'is-link'
                else if (senha.tipoId == 4)
                    return 'is-warning'
                else if (senha.tipoId == 5)
                    return 'is-success'
                else if (senha.tipoId == 6)
                    return 'is-danger'
            },
            salvarGuicheAtual() {
                localStorage.guicheAtual = this.guicheAtual
            },
            salvarGuicheTipo() {
                localStorage.guicheTipo = this.guicheTipo

                //filtra a lista
            },
            playBeep() {
                const audio = new Audio('http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3')
                audio.play()
            },
            chamar(senha) {
                this.$notificacaoHub.invoke("ChamarSenhaAtendimento",
                    senha.id,
                    this.guicheAtual)
                    .then(() => {
                        this.chamandoSenhaId = senha.id
                        this.playBeep()
                    })
                    .catch(e => {
                        this.isLoading = false
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: e.toString(),
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    })
            },
            atender(senha) {
                this.$notificacaoHub.invoke("AtenderSenhaAtendimento",
                    senha.id,
                    this.usuario.id)
                    .then(() => {
                        this.$store.commit('senhaAtendimento/SET_ATENDENDO', senha)
                    })
                    .catch(e => {
                        this.isLoading = false
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: e.toString(),
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    })
            },
            cancelar(senha) {
                this.$notificacaoHub.invoke("CancelarSenhaAtendimento",
                    senha.id)
                    .catch(e => {
                        this.isLoading = false
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: e.toString(),
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    })
            }
        }
    }
</script>